import { useState, Fragment, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, Navigate, useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Sidebar from "../components/Sidebar";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Box from "@mui/material/Box";
import { API_URL, alertMessage } from "../actions/auth";
import Button from "@mui/material/Button";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Collapse from "@mui/material/Collapse";
import { DatePicker } from "@mui/x-date-pickers";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import moment from "moment";
import Alert from "@mui/material/Alert";
import CloseIcon from "@mui/icons-material/Close";
import html2pdf from "html3pdf";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import Fab from "@mui/material/Fab";

const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

function Attendance() {
  const dispatch = useDispatch();
  function createAlert(type, title, message) {
    dispatch(alertMessage(type, title, message));
  }
  const navigate = useNavigate();
  const params = new URLSearchParams(window.location.search);
  const state = useSelector((state) => state);

  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [selectedDate, setSelectedDate] = useState(new Date());

  const handleDateChange = (date) => {
    setLoadAttendance(true);
    setDownloadPdf(false);
    setSelectedDate(date);
  };

  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingDownload, setIsLoadingDownload] = useState(false);

  const [isLoadingAttendance, setIsLoadingAttendance] = useState(false);
  const [logo, setLogo] = useState(false);

  const [subject, setSubject] = useState(null);
  const [alertText, setAlertText] = useState("");
  const [alert, setAlert] = useState(false);
  const [attendance, setAttendance] = useState({});
  const [attendanceResult, setAttendanceResult] = useState("");

  const [allAttendance, setAllAttendance] = useState({});
  const [currentStudent, setCurrentStudent] = useState("");
  const [classList, setClassList] = useState([]);
  const [currentClass, setCurrentClass] = useState("");
  const [currentDivision, setCurrentDivision] = useState("");
  const [currentSemester, setCurrentSemester] = useState("");

  const [currentStudentList, setCurrentStudentList] = useState([]);
  const [currentSubject, setCurrentSubject] = useState(null);
  const [isReady, setIsReady] = useState(false);

  const [sortBySemester, setSortBySemester] = useState(false);
  const [sortBySubject, setSortBySubject] = useState(true);
  const [sortByDate, setSortByDate] = useState(false);
  const [sortByStudent, setSortByStudent] = useState(false);
  const [sortByClass, setSortByClass] = useState(true);
  const today = new Date();
  const [month, setMonth] = useState(today.getMonth());

  const [loadAttendance, setLoadAttendance] = useState(false);
  const [downloadPdf, setDownloadPdf] = useState(true);

  function getData(classs, division, subj) {
    setIsLoadingAttendance(true);
    var body = {};
    const config = {
      headers: {
        Authorization: state.auth.token,
        "X-CSRFToken": state.auth.csrfToken,
        "Content-Type": "application/json",
      },
    };
    if (sortBySemester) {
      if (currentSemester != null) {
        body["semester_id"] = currentSemester;
      } else {
        setAlertText("Please select Semester.");
        setAlert(true);
        setIsLoading(false);
        setIsLoadingAttendance(false);
        return null;
      }
    } else {
      body["semester_id"] = null;
    }

    if (sortByStudent) {
      if (currentStudent != null && currentStudent != 0) {
        body["email"] = currentStudent;
      } else {
        setAlertText("Please select Student.");
        setAlert(true);
        setIsLoading(false);
        setIsLoadingAttendance(false);
        return null;
      }
    } else {
      body["email"] = null;
    }

    if (sortByDate) {
      if (selectedDate != null && body["semester_id"] != null) {
        body["date"] = moment(selectedDate).format("DD-MM-YYYY");
      } else {
        if (selectedDate == null) {
          setAlertText("Please select Date.");
          setAlert(true);
          setIsLoading(false);
          setIsLoadingAttendance(false);
          return null;
        } else {
          setAlertText("Please select Semester.");
          setAlert(true);
          setIsLoading(false);
          setIsLoadingAttendance(false);
          return null;
        }
      }
    } else {
      body["date"] = null;
    }

    if (sortBySubject) {
      if (subj != null) {
        body["subject_id"] = subj;
      } else {
        if (currentSubject != null) {
          body["subject_id"] = currentSubject;
        } else {
          setAlertText("Please select Subject.");
          setAlert(true);
          setIsLoading(false);
          setIsLoadingAttendance(false);
          return null;
        }
      }
    } else {
      body["subject_id"] = null;
    }

    body["institution_id"] = state.auth.user.institution_id;
    body["class_id"] = classs;
    body["division"] = division;

    body = JSON.stringify(body);
    axios.post(API_URL + "/check_attendance", body, config).then((res) => {
      console.log(res.data);
      setAttendanceResult(res.data);
      setIsLoading(false);
      setIsLoadingAttendance(false);
      setDownloadPdf(true);
      setLoadAttendance(false);
    });
  }

  function getStudents(classs, division) {
    var body = {};
    const config = {
      headers: {
        Authorization: state.auth.token,
        "X-CSRFToken": state.auth.csrfToken,
        "Content-Type": "application/json",
      },
    };
    body["class_id"] = classs;
    body["division"] = division;
    body["institution_id"] = state.auth.user.institution_id;
    body = JSON.stringify(body);

    axios
      .post(API_URL + "/get_students_list_class_division", body, config)
      .then((res) => {
        setCurrentStudentList(res.data);
        if (res.data.length != 0) {
          setCurrentStudent(res.data[0]["email"]);
        }
        setIsLoading(false);
      });
  }

  function handleStudentChange(e) {
    setCurrentStudent(e.target.value);
    setDownloadPdf(false);
    setLoadAttendance(true);
    setDownloadPdf(false);
    setLoadAttendance(true);
  }

  const handleClassChange = (e) => {
    setCurrentClass(e.target.value);
    getStudents(e.target.value, currentDivision);
    setDownloadPdf(false);
    setLoadAttendance(true);
  };

  const handleDivisionChange = (e) => {
    setCurrentDivision(e.target.value);
    getStudents(currentClass, e.target.value);
    setDownloadPdf(false);
    setLoadAttendance(true);
  };

  function handleSemesterChange(e) {
    setCurrentSemester(e.target.value);
    setAttendance(allAttendance[e.target.value]);
    setDownloadPdf(false);
    setLoadAttendance(true);
  }

  const handleSubChange = (e) => {
    setCurrentSubject(e.target.value);
    setDownloadPdf(false);
    setLoadAttendance(true);
  };

  function getClassList() {
    var classes = Object.keys(classList).map((classs, index) => (
      <MenuItem value={classs}>{classs}</MenuItem>
    ));
    return classes;
  }

  function getDivisionList() {
    var classes = Object.keys(classList[currentClass]).map(
      (division, index) => <MenuItem value={division}>{division}</MenuItem>
    );
    return classes;
  }

  function getSemesterList() {
    var semesters = Object.keys(classList[currentClass][currentDivision]).map(
      (semester, index) => <MenuItem value={semester}>{semester}</MenuItem>
    );
    return semesters;
  }

  function getSubjectList() {
    var subjects = classList[currentClass][currentDivision][
      currentSemester
    ].map((subject) => (
      <MenuItem value={Object.keys(subject)[0]}>
        {Object.keys(subject)[0]}
      </MenuItem>
    ));
    return subjects;
  }
  function getAllStudents() {
    var students;

    if (currentStudentList.length != 0) {
      students = currentStudentList.map((student) => (
        <MenuItem value={student["email"]}>{student["name"]}</MenuItem>
      ));
    } else {
      students = <MenuItem value={""}>No students</MenuItem>;
    }

    return students;
  }

  useEffect(() => {
    if (state.auth.isLoading) {
      dispatch({ type: "ENTRY_PAGE", payload: window.location.pathname });
      navigate("/", { replace: true });
    } else {
      if (state.auth.initiated && state.auth.finalized) {
        getLogo();

        var body = {};
        const config = {
          headers: {
            Authorization: state.auth.token,
            "X-CSRFToken": state.auth.csrfToken,
            "Content-Type": "application/json",
          },
        };
        body["institution_id"] = state.auth.user.institution_id;

        body = JSON.stringify(body);
        axios.post(API_URL + "/get_class_list", body, config).then((res) => {
          var classListTemp = res.data;

          setClassList(classListTemp);
          setCurrentClass(Object.keys(classListTemp)[0]);
          setCurrentDivision(
            Object.keys(classListTemp[Object.keys(classListTemp)[0]])[0]
          );
          setCurrentSemester(
            Object.keys(
              classListTemp[Object.keys(classListTemp)[0]][
                Object.keys(classListTemp[Object.keys(classListTemp)[0]])[0]
              ]
            )[0]
          );
          setCurrentSubject(
            Object.keys(
              classListTemp[Object.keys(classListTemp)[0]][
                Object.keys(classListTemp[Object.keys(classListTemp)[0]])[0]
              ][
                Object.keys(
                  classListTemp[Object.keys(classListTemp)[0]][
                    Object.keys(classListTemp[Object.keys(classListTemp)[0]])[0]
                  ]
                )[0]
              ][0]
            )[0]
          );

          setIsReady(true);
          getStudents(
            Object.keys(classListTemp)[0],
            Object.keys(classListTemp[Object.keys(classListTemp)[0]])[0]
          );
          getData(
            Object.keys(classListTemp)[0],
            Object.keys(classListTemp[Object.keys(classListTemp)[0]])[0],
            Object.keys(
              classListTemp[Object.keys(classListTemp)[0]][
                Object.keys(classListTemp[Object.keys(classListTemp)[0]])[0]
              ][
                Object.keys(
                  classListTemp[Object.keys(classListTemp)[0]][
                    Object.keys(classListTemp[Object.keys(classListTemp)[0]])[0]
                  ]
                )[0]
              ][0]
            )[0]
          );
        });
      } else {
        createAlert(
          "ERROR",
          "Institution setup incomplete",
          "Please add subjects and assign teachers."
        );
      }
    }
  }, []);

  if (state.auth.refresh == "REFRESH_DATA") {
    getData(currentClass, currentDivision, null);
    dispatch({ type: "REFRESH_DATA", payload: null });
  }

  async function getLogo() {
    const base64 = await fetch(
      API_URL + "/media/" + state.auth.user.institution_id + ".jpg"
    )
      .then((response) => response.blob())
      .then((blob) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        return new Promise((res) => {
          reader.onloadend = () => {
            res(reader.result);
          };
        });
      })
      .then((res) => {
        setLogo(res);
      });
  }

  const getDaysArray = (function () {
    const names = Object.freeze([
      "SUN",
      "MON",
      "TUE",
      "WED",
      "THU",
      "FRI",
      "SAT",
    ]);
    return (year, month) => {
      const monthIndex = month - 1;
      const date = new Date(year, monthIndex, 1);
      const result = [];

      while (date.getMonth() == monthIndex) {
        if (date.getDay() != 0) {
          var dat = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
          var mon = date.getMonth() < 10 ? "0" + month : month;

          result.push(
            dat + "-" + mon + "-" + year + "#" + names[date.getDay()]
          );
        }
        date.setDate(date.getDate() + 1);
      }
      return result;
    };
  })();

  function selectedPageContent() {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div
          style={{
            width: "50%",
            float: "left",
            overflowX: "scroll",
            height: "88vh",
            paddingLeft: "50px",
          }}
        >
          <div
            style={{
              margin: "0px 0px 0px 110px",
              overflow: "hidden",
              padding: "10px",
              margin: "-20px",
              marginLeft: "-50px",
              marginRight: "50px",
            }}
          >
            <div
              style={{
                padding: "50px",
                paddingBottom: "-500px",
                marginTop: "-20px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
              id={"pdf-element"}
            >
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "30px",
                }}
              >
                <a
                  style={{ textDecoration: "none" }}
                  href={"https://principal.elemzy.com"}
                >
                  <img
                    style={{
                      maxHeight: "50px",
                      marginTop: "5px",
                      marginRight: "15px",
                    }}
                    src={require("../img/appbar.png")}
                  />
                </a>

                {logo ? (
                  <img
                    style={{
                      maxHeight: "50px",
                      marginTop: "5px",
                      marginRight: "15px",
                    }}
                    src={logo}
                  />
                ) : (
                  ""
                )}
              </div>
              <h2
                style={{
                  width: "100%",
                  textAlign: "center",
                  marginTop: "-25px",
                  marginBottom: "20px",
                  fontSize: "30px",
                }}
              >
                {sortByDate
                  ? moment(selectedDate).format("Do MMMM, YYYY") +
                    " - " +
                    currentSubject
                  : currentSemester + " - " + monthNames[month - 1] + " 2024"}
              </h2>
              {getAttendance()}
            </div>
          </div>
        </div>
        <div
          style={{
            height: "88vh",
            width: "15%",
            float: "left",
            overflow: "scroll",
          }}
        >
          <div
            style={{
              margin: "0px 10px 0px 10px",
            }}
          >
            <span
              style={{
                color: "#3182bd",
                fontFamily: "Lato-Regular",
                fontSize: "14px",
              }}
            >
              Semester
            </span>
            <Fragment>
              <Select
                style={{
                  marginTop: "2px",
                  height: "40px",
                  marginBottom: "5px",
                }}
                variant={"outlined"}
                fullWidth
                value={currentSemester}
                onChange={(e) => handleSemesterChange(e)}
              >
                {getSemesterList()}
              </Select>
            </Fragment>
            <br></br>
            <span
              style={{
                color: "#3182bd",
                fontFamily: "Lato-Regular",
                fontSize: "14px",
              }}
            >
              Class
            </span>
            <br></br>
            <Select
              style={{
                marginTop: "2px",
                height: "40px",
                marginBottom: "15px",
              }}
              fullWidth
              value={currentClass}
              onChange={(e) => handleClassChange(e)}
              variant={"outlined"}
            >
              {getClassList()}
            </Select>
            <span
              style={{
                color: "#3182bd",
                fontFamily: "Lato-Regular",
                fontSize: "14px",
              }}
            >
              Division
            </span>
            <Select
              value={currentDivision}
              onChange={(e) => handleDivisionChange(e)}
              variant={"outlined"}
              fullWidth
              style={{
                marginTop: "2px",
                height: "40px",
                marginBottom: "5px",
              }}
            >
              {getDivisionList()}
            </Select>
            <br></br>
            <span
              style={{
                color: "#3182bd",
                fontFamily: "Lato-Regular",
                fontSize: "14px",
              }}
            >
              Subject
            </span>
            <Select
              value={currentSubject}
              onChange={(e) => handleSubChange(e)}
              variant={"outlined"}
              fullWidth
              style={{
                marginTop: "2px",
                height: "40px",
                marginBottom: "5px",
              }}
            >
              {getSubjectList()}
            </Select>
            <br></br>
            {!sortByDate ? (
              <Fragment>
                {" "}
                <span
                  style={{
                    color: "#3182bd",
                    fontFamily: "Lato-Regular",
                    fontSize: "14px",
                  }}
                >
                  Month
                </span>
                <Fragment>
                  <Select
                    value={month}
                    onChange={(e) => setMonth(e.target.value)}
                    variant={"outlined"}
                    fullWidth
                    style={{
                      marginTop: "2px",
                      height: "40px",
                      marginBottom: "5px",
                    }}
                  >
                    <MenuItem value={1}>January</MenuItem>
                    <MenuItem value={2}>February</MenuItem>
                    <MenuItem value={3}>March</MenuItem>
                    <MenuItem value={4}>April</MenuItem>
                    <MenuItem value={5}>May</MenuItem>
                    <MenuItem value={6}>June</MenuItem>
                    <MenuItem value={7}>July</MenuItem>
                    <MenuItem value={8}>August</MenuItem>
                    <MenuItem value={9}>September</MenuItem>
                    <MenuItem value={10}>October</MenuItem>
                    <MenuItem value={11}>November</MenuItem>
                    <MenuItem value={12}>December</MenuItem>
                  </Select>
                </Fragment>
              </Fragment>
            ) : (
              ""
            )}
            <br></br>
            <Checkbox
              labelStyle={{ color: "#3182bd" }}
              iconStyle={{ fill: "white" }}
              inputStyle={{ color: "#3182bd" }}
              style={{ marginBottom: "2px", color: "#3182bd" }}
              checked={sortByDate}
              onChange={(e) => {
                setSortByDate(e.target.checked);
                setSortBySemester(e.target.checked);
                setLoadAttendance(true);
                setDownloadPdf(false);
              }}
              value={1}
              color={"primary"}
            />{" "}
            <span
              style={{
                color: "#3182bd",
                fontFamily: "Lato-Regular",
                fontSize: "14px",
              }}
            >
              Date
            </span>
            {sortByDate ? (
              <Fragment>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    inputFormat="dd-MM-yyyy"
                    value={selectedDate}
                    sx={{
                      height: "40px",
                      width: "100%",
                      marginTop: "5px",
                      marginBottom: "20px",
                    }}
                    onChange={handleDateChange}
                    fullWidth
                    renderInput={(params) => (
                      <TextField
                        style={{
                          width: "100%",
                          marginTop: "2px",
                          marginBottom: "5px",
                          height: "40px",
                        }}
                        size="small"
                        fullWidth
                        {...params}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Fragment>
            ) : (
              <Fragment></Fragment>
            )}
            {/* <br></br> */}
            {/* <Checkbox
              labelStyle={{ color: "#3182bd" }}
              iconStyle={{ fill: "white" }}
              inputStyle={{ color: "#3182bd" }}
              style={{ marginBottom: "2px", color: "#3182bd" }}
              checked={sortByStudent}
              onChange={(e) => setSortByStudent(e.target.checked)}
              value={1}
              color={"primary"}
            />{" "}
            <span
              style={{
                color: "#3182bd",
                fontFamily: "Lato-Regular",
                fontSize: "14px",
              }}
            >
              Student
            </span>
            {sortByStudent ? (
              <Fragment>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={currentStudent}
                  onChange={(e) => handleStudentChange(e)}
                  variant={"outlined"}
                  fullWidth
                  style={{
                    marginTop: "2px",
                    height: "40px",
                    marginBottom: "5px",
                  }}
                >
                  {getAllStudents()}
                </Select>
              </Fragment>
            ) : (
              <Fragment></Fragment>
            )} */}
            <br></br>
            <br></br>
            {loadAttendance ? (
              <Button
                style={{
                  height: "35px",
                  margin: "6px 0px 15px 0px",
                  border: "1px solid #3182bd",
                  backgroundColor: "#3182bd",
                  boxShadow: "none",
                }}
                variant={"contained"}
                color="primary"
                fullWidth
                onClick={() => getData(currentClass, currentDivision, null)}
              >
                <h4>LOAD ATTENDANCE</h4>
              </Button>
            ) : (
              ""
            )}
            <Box sx={{ width: "100%" }}>
              <Collapse in={alert}>
                <Alert
                  action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => {
                        setAlert(false);
                      }}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }
                  severity="error"
                  sx={{ mb: 2 }}
                >
                  {alertText}
                </Alert>
              </Collapse>
            </Box>
            {downloadPdf ? (
              <Button
                style={{
                  height: "35px",
                  margin: "6px 0px 15px 0px",
                  border: "1px solid #3182bd",
                  backgroundColor: "#3182bd",
                  boxShadow: "none",
                }}
                variant={"contained"}
                color="primary"
                fullWidth
                onClick={() => {
                  setIsLoadingDownload(true);
                  setExpanded(true);
                  var element = document.getElementById("pdf-element");
                  html2pdf(element).then((_) => {
                    setIsLoadingDownload(false);
                    createAlert(
                      "SUCCESS",
                      "Completed",
                      "Attendance downloaded in pdf"
                    );
                  });
                }}
              >
                <h4>Download PDF</h4>
              </Button>
            ) : (
              ""
            )}
            <br />
            {isLoadingDownload ? (
              <div
                style={{
                  marginTop: "60px",
                  padding: "20px",
                  width: "100%",
                  backgroundColor: "white",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <CircularProgress />
                <h3 style={{ textAlign: "center" }}>
                  Downloading attendance
                  <br />
                  This might take a couple of minutes...
                </h3>
              </div>
            ) : (
              <Fragment></Fragment>
            )}
          </div>
        </div>
      </div>
    );
  }

  var noresult = "";

  function getAttendance() {
    if (isLoading || !isReady) {
      return (
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            backgroundColor: "white",
            marginTop: "30vh",
          }}
        >
          <CircularProgress />
        </div>
      );
    } else {
      if (isLoadingAttendance) {
        return (
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              backgroundColor: "white",
              marginTop: "30vh",
            }}
          >
            <CircularProgress />
          </div>
        );
      } else {
        var type = attendanceResult["type"];
        var results = attendanceResult["result"];
        if (type == null || results.length == 0) {
          return (
            <Fragment>
              <p style={{ fontSize: "20px" }}>No results to display.</p>
            </Fragment>
          );
        } else {
          console.log(type);
          if (type == "6") {
            return (
              <Fragment>
                {results.map((result, index) =>
                  Object.keys(result).map((a, i) => (
                    <Fragment>
                      {Object.keys(result[a]["attendance"]).length == 0 ? (
                        ""
                      ) : (
                        <Fragment>
                          <h2 style={{ marginTop: "0px" }}>{a}</h2>

                          <b>
                            Attendance percent:{" "}
                            {result[a]["attendance_percent"]}%
                          </b>
                          <br></br>
                          <br></br>
                          {Object.keys(result[a]["subjects"]).map((b, j) => (
                            <Fragment>
                              <Box
                                p={2}
                                style={{
                                  minHeight: "20px",
                                  borderRadius: "6px",
                                  backgroundColor: "#d7ecff",
                                  color: "black",
                                }}
                              >
                                <b>{b}</b>
                                <br></br>
                                <br></br>
                                <Grid container>
                                  <Grid item xs={11}>
                                    Total lectures:
                                    {" " + result[a]["subjects"][b]["total"]}
                                    <br></br>
                                    Attended:
                                    {" " + result[a]["subjects"][b]["attended"]}
                                  </Grid>
                                  <Grid item xs={1}>
                                    <IconButton
                                      style={{
                                        backgroundColor: "#3182bd",
                                        color: "white",
                                      }}
                                      onClick={handleExpandClick}
                                      aria-expanded={expanded}
                                      aria-label="show more"
                                      size="large"
                                    >
                                      {expanded ? (
                                        <ExpandLessIcon />
                                      ) : (
                                        <ExpandMoreIcon />
                                      )}
                                    </IconButton>
                                  </Grid>
                                </Grid>
                                <br></br>
                                <Collapse
                                  in={expanded}
                                  timeout="auto"
                                  unmountOnExit
                                >
                                  {Object.keys(result[a]["attendance"]).map(
                                    (c, k) => (
                                      <Fragment>
                                        {Object.keys(
                                          result[a]["attendance"][c]
                                        ).includes(b) ? (
                                          <Box
                                            p={2}
                                            style={{
                                              minHeight: "10px",
                                              borderRadius: "6px",
                                              backgroundColor: "#ffffff",
                                              color: "black",
                                            }}
                                          >
                                            <b>
                                              {moment(c, "DD-MM-YYYY").format(
                                                "Do MMMM, YYYY"
                                              )}
                                            </b>

                                            <br></br>

                                            <div>
                                              {Object.keys(
                                                result[a]["attendance"][c][b]
                                              ).map((d, l) => (
                                                <Fragment>
                                                  {d}.&nbsp;
                                                  {result[a]["attendance"][c][
                                                    b
                                                  ][d] == true
                                                    ? l + ". Present"
                                                    : l + ". Absent"}
                                                  <br></br>
                                                </Fragment>
                                              ))}
                                            </div>
                                          </Box>
                                        ) : (
                                          <Fragment></Fragment>
                                        )}
                                        <br></br>
                                      </Fragment>
                                    )
                                  )}
                                </Collapse>
                              </Box>
                              <br></br>
                            </Fragment>
                          ))}
                          <br></br>
                        </Fragment>
                      )}
                    </Fragment>
                  ))
                )}
              </Fragment>
            );
          } else if (type == 2) {
            return (
              <Fragment>
                {results.map((result, index) => (
                  <Box
                    p={2}
                    style={{
                      minHeight: "20px",
                      borderRadius: "6px",
                      backgroundColor: "#d7ecff",
                      color: "black",
                    }}
                  >
                    <b>{Object.keys(result)[0]}</b> <br></br>
                    <br></br>
                    <Fragment>
                      {Object.keys(result[Object.keys(result)[0]]).map(
                        (a, l) => (
                          <Fragment>
                            {a}.&nbsp;
                            {result[Object.keys(result)[0]][a] == true
                              ? l + ". Present"
                              : l + ". Absent"}
                            <br></br>
                          </Fragment>
                        )
                      )}
                    </Fragment>
                  </Box>
                ))}
              </Fragment>
            );
          } else if (type == 3) {
            return (
              <Fragment>
                {results.map((result, index) => (
                  <Box
                    p={2}
                    style={{
                      minHeight: "10px",
                      borderRadius: "6px",
                      backgroundColor: "#d7ecff",
                      color: "black",
                    }}
                  >
                    {Object.keys(result).map((d, l) => (
                      <Fragment>
                        {d}.&nbsp;
                        {result[d] == true ? l + ". Present" : l + ". Absent"}
                        <br></br>
                      </Fragment>
                    ))}
                  </Box>
                ))}
                <br></br>
              </Fragment>
            );
          } else if (type == 5) {
            return (
              <div style={{ marginTop: "-20px" }}>
                {results.map((result, index) =>
                  Object.keys(result).map((a, i) => (
                    <Fragment>
                      {Object.keys(result[a]["attendance"]).length == 0 ? (
                        ""
                      ) : (
                        <Fragment>
                          <h3>{a}</h3>
                          {Object.keys(result[a]["subjects"]).map((b, j) => (
                            <Fragment>
                              {b == currentSubject ? (
                                <Box
                                  p={2}
                                  style={{
                                    minHeight: "20px",
                                    borderRadius: "6px",
                                    backgroundColor: "#d7ecff ",
                                    color: "black",
                                  }}
                                >
                                  <h3 style={{ marginTop: "0px" }}>{b}</h3>
                                  <Grid container>
                                    <Grid item xs={11}>
                                      Total lectures:
                                      {" " + result[a]["subjects"][b]["total"]}
                                      <br></br>
                                      Attended:
                                      {" " +
                                        result[a]["subjects"][b]["attended"]}
                                    </Grid>
                                    <Grid item xs={1}>
                                      <IconButton
                                        style={{
                                          backgroundColor: "#3182bd",
                                          color: "white",
                                        }}
                                        onClick={handleExpandClick}
                                        aria-expanded={expanded}
                                        aria-label="show more"
                                        size="large"
                                      >
                                        {expanded ? (
                                          <ExpandLessIcon />
                                        ) : (
                                          <ExpandMoreIcon />
                                        )}
                                      </IconButton>
                                    </Grid>
                                  </Grid>
                                  <br></br>
                                  <Collapse
                                    in={expanded}
                                    timeout="auto"
                                    unmountOnExit
                                  >
                                    {Object.keys(result[a]["attendance"]).map(
                                      (c, k) => (
                                        <Fragment>
                                          <Box
                                            p={2}
                                            style={{
                                              minHeight: "10px",
                                              borderRadius: "6px",
                                              backgroundColor: "#ffffff",
                                              color: "black",
                                            }}
                                          >
                                            <b>
                                              {moment(c, "DD-MM-YYYY").format(
                                                "Do MMMM, YYYY"
                                              )}
                                            </b>
                                            <br></br>
                                            {currentSubject in
                                            result[a]["attendance"][c] ? (
                                              <Fragment>
                                                {Object.keys(
                                                  result[a]["attendance"][c][b]
                                                ).map((d, l) => (
                                                  <Fragment>
                                                    {d}.&nbsp;
                                                    {result[a]["attendance"][c][
                                                      b
                                                    ][d] == true
                                                      ? l + ". Present"
                                                      : l + ". Absent"}
                                                    <br></br>
                                                  </Fragment>
                                                ))}
                                              </Fragment>
                                            ) : (
                                              ""
                                            )}
                                          </Box>
                                          <br></br>
                                        </Fragment>
                                      )
                                    )}
                                  </Collapse>
                                </Box>
                              ) : (
                                ""
                              )}
                            </Fragment>
                          ))}
                        </Fragment>
                      )}
                    </Fragment>
                  ))
                )}
              </div>
            );
          } else if (type == 4) {
            return (
              <Fragment>
                <br></br>
                <Box
                  p={2}
                  style={{
                    marginTop: "-20px",
                    minHeight: "20px",
                    borderRadius: "6px",
                    backgroundColor: "#d7ecff",
                    color: "black",
                  }}
                >
                  {results.map((result, index) =>
                    Object.keys(result["subjects"]).map((subject, index) =>
                      subject == currentSubject ? (
                        <div style={{ marginTop: "-20px" }}>
                          <h2>{currentSubject}</h2>
                          Total lectures :
                          {result["subjects"][currentSubject]["total"]}
                          <br></br>
                          Attended :
                          {result["subjects"][currentSubject]["attended"]}
                          <br></br>
                          <br></br>
                          {Object.keys(result["attendance"]).map((b, j) => (
                            <span>
                              <Box
                                p={2}
                                style={{
                                  minHeight: "20px",
                                  borderRadius: "6px",
                                  backgroundColor: "#ffffff",
                                  color: "black",
                                }}
                              >
                                <b>
                                  {moment(b, "DD-MM-YYYY").format(
                                    "Do MMMM, YYYY"
                                  )}
                                </b>
                                <br></br>
                                {Object.keys(result["attendance"][b]).map(
                                  (c, k) =>
                                    c == currentSubject
                                      ? Object.keys(
                                          result["attendance"][b][c]
                                        ).map((d, l) =>
                                          result["attendance"][b][c][d] ? (
                                            <span>
                                              {l}. Present<br></br>
                                            </span>
                                          ) : (
                                            <span>
                                              {l}. Absent<br></br>
                                            </span>
                                          )
                                        )
                                      : ""
                                )}
                              </Box>
                              <br></br>
                            </span>
                          ))}
                        </div>
                      ) : (
                        <span>
                          {noresult != "No restuls to display."
                            ? ""
                            : "No restuls to display."}
                          <span style={{ display: "none" }}>
                            {(noresult = "No restuls to display.")}
                          </span>
                        </span>
                      )
                    )
                  )}
                </Box>
              </Fragment>
            );
          } else if (type == "1") {
            return (
              <Fragment>
                {results.map((result, index) => (
                  <div style={{ marginTop: "-25px" }}>
                    <h2>{result["name"]}</h2>

                    <div style={{ fontSize: "18px", marginTop: "-20px" }}>
                      Attendance percent: {result["attendance_percent"]} %
                    </div>
                    <br></br>
                    {Object.keys(result["subjects"]).map((b, j) => (
                      <Fragment>
                        <Box
                          p={2}
                          style={{
                            minHeight: "20px",
                            borderRadius: "6px",
                            backgroundColor: "#d7ecff",
                            color: "black",
                          }}
                        >
                          <b style={{ fontSize: "20px" }}>{b}</b>
                          <br></br>
                          <Grid container>
                            <Grid item xs={11}>
                              Total lectures: {result["subjects"][b]["total"]}
                              <br></br>
                              Attended: {result["subjects"][b]["attended"]}
                            </Grid>
                            <Grid item xs={1}>
                              <IconButton
                                style={{
                                  backgroundColor: "#3182bd",
                                  color: "white",
                                }}
                                onClick={handleExpandClick}
                                aria-expanded={expanded}
                                aria-label="show more"
                                size="large"
                              >
                                {expanded ? (
                                  <ExpandLessIcon />
                                ) : (
                                  <ExpandMoreIcon />
                                )}
                              </IconButton>
                            </Grid>
                          </Grid>
                          <br></br>
                          <Collapse in={expanded} timeout="auto" unmountOnExit>
                            {Object.keys(result["attendance"]).map((c, k) => (
                              <Fragment>
                                <Box
                                  p={2}
                                  style={{
                                    minHeight: "10px",
                                    borderRadius: "6px",
                                    backgroundColor: "#ffffff",
                                    color: "black",
                                  }}
                                >
                                  <b>
                                    {moment(c, "DD-MM-YYYY").format(
                                      "Do MMMM, YYYY"
                                    )}
                                  </b>
                                  <br></br>
                                  {Object.keys(result["attendance"][c][b]).map(
                                    (d, l) => (
                                      <Fragment>
                                        {d}.&nbsp;
                                        {result["attendance"][c][b][d] == true
                                          ? l + ". Present"
                                          : l + ". Absent"}
                                        <br></br>
                                      </Fragment>
                                    )
                                  )}
                                </Box>
                                <br></br>
                              </Fragment>
                            ))}
                          </Collapse>
                        </Box>
                        <br></br>
                      </Fragment>
                    ))}
                    <br></br>
                  </div>
                ))}
              </Fragment>
            );
          } else if (type == 8) {
            return (
              <Fragment>
                {results.map((result, index) =>
                  Object.keys(result).map((a, i) => (
                    <Box
                      p={2}
                      style={{
                        minHeight: "10px",
                        borderRadius: "6px",
                        backgroundColor: "#d7ecff",
                        color: "black",
                      }}
                    >
                      <h2 style={{ marginTop: "0px" }}> {a.split("#")[1]}</h2>

                      {Object.keys(result[a]).map((c, k) => (
                        <Fragment>
                          <Box
                            p={2}
                            style={{
                              minHeight: "10px",
                              borderRadius: "6px",
                              backgroundColor: "#ffffff",
                              color: "black",
                            }}
                          >
                            <b>{c}</b>
                            <br></br>
                            {Object.keys(result[a][c]).map((d, l) => (
                              <Fragment>
                                {d}.&nbsp;
                                {result[a][c][d] == true
                                  ? l + ". Present"
                                  : l + ". Absent"}
                                <br></br>
                              </Fragment>
                            ))}
                          </Box>
                          <br></br>
                        </Fragment>
                      ))}
                    </Box>
                  ))
                )}
              </Fragment>
            );
          } else if (type == 9) {
            return (
              <Fragment>
                {results.map((result, index) =>
                  Object.keys(result).map((a, i) => (
                    <span>
                      <b style={{ fontSize: "25px" }}> {a.split("#")[1]}</b>
                      <br></br>
                      Total lectures: {result[a]["total"]}
                      <br></br>Attended:{result[a]["attended"]}
                      <br></br>
                      <br></br>
                      {Object.keys(result[a]["attendance"]).map((b, j) => (
                        <Fragment>
                          <Box
                            p={2}
                            style={{
                              minHeight: "20px",
                              borderRadius: "6px",
                              backgroundColor: "#d7ecff",
                              color: "black",
                            }}
                          >
                            <b>
                              {moment(b, "DD-MM-YYYY").format("Do MMMM, YYYY")}
                            </b>
                            <br></br>
                            {Object.keys(result[a]["attendance"][b]).map(
                              (c, k) =>
                                c == currentSubject
                                  ? Object.keys(
                                      result[a]["attendance"][b][c]
                                    ).map((d, l) => (
                                      <Fragment>
                                        {d}.&nbsp;
                                        {result[a]["attendance"][b][c][d] ==
                                        true
                                          ? l + ". Present"
                                          : l + ". Absent"}
                                        <br></br>
                                      </Fragment>
                                    ))
                                  : ""
                            )}
                          </Box>
                          <br></br>
                        </Fragment>
                      ))}
                    </span>
                  ))
                )}
              </Fragment>
            );
          } else if (type == 12) {
            return (
              <Fragment>
                {Object.entries(results).map(([key, value]) => (
                  <Fragment>
                    <h2 style={{ marginTop: "0px" }}>{key}</h2>

                    {value.map((result, x) => (
                      <Fragment>
                        <b style={{ fontSize: "25px" }}>
                          {Object.keys(result)[0].split("#")[1]}
                        </b>
                        <br></br>
                        <span style={{ fontSize: "20px" }}>
                          Attendance percent:
                          {" " +
                            result[Object.keys(result)[0]][
                              "attendance_percent"
                            ]}
                          %
                        </span>
                        <br></br>
                        <br></br>
                        {Object.keys(
                          result[Object.keys(result)[0]]["subjects"]
                        ).map((b, j) => (
                          <Fragment>
                            <Box
                              p={2}
                              style={{
                                minHeight: "20px",
                                borderRadius: "6px",
                                backgroundColor: "#d7ecff",
                                color: "black",
                              }}
                            >
                              <b
                                style={{
                                  fontSize: "18px",
                                }}
                              >
                                {b}
                              </b>

                              <Grid container style={{ marginTop: "5px" }}>
                                <Grid item xs={11}>
                                  Total lectures:
                                  {" " +
                                    result[Object.keys(result)[0]]["subjects"][
                                      b
                                    ]["total"]}
                                  <br></br>
                                  Attended:
                                  {" " +
                                    result[Object.keys(result)[0]]["subjects"][
                                      b
                                    ]["attended"]}
                                </Grid>
                                <Grid item xs={1}>
                                  <IconButton
                                    style={{
                                      backgroundColor: "#3182bd",
                                      color: "white",
                                    }}
                                    onClick={handleExpandClick}
                                    aria-expanded={expanded}
                                    aria-label="show more"
                                    size="large"
                                  >
                                    {expanded ? (
                                      <ExpandLessIcon />
                                    ) : (
                                      <ExpandMoreIcon />
                                    )}
                                  </IconButton>
                                </Grid>
                              </Grid>
                              <br></br>
                              <Collapse
                                in={expanded}
                                timeout="auto"
                                unmountOnExit
                              >
                                {Object.keys(
                                  result[Object.keys(result)[0]]["attendance"]
                                ).map((c, k) => (
                                  <Fragment>
                                    {Object.keys(
                                      result[Object.keys(result)[0]][
                                        "attendance"
                                      ][c]
                                    ).includes(b) ? (
                                      <Fragment>
                                        <Box
                                          p={1}
                                          style={{
                                            minHeight: "10px",
                                            borderRadius: "6px",
                                            backgroundColor: "#ffffff",
                                            color: "black",
                                          }}
                                        >
                                          <b>
                                            {moment(c, "DD-MM-YYYY").format(
                                              "Do MMMM, YYYY"
                                            )}
                                          </b>
                                          <br></br>
                                          {Object.keys(
                                            result[Object.keys(result)[0]][
                                              "attendance"
                                            ][c][b]
                                          ).map((d, l) => (
                                            <Fragment>
                                              {d}.&nbsp;
                                              {result[Object.keys(result)[0]][
                                                "attendance"
                                              ][c][b][d] == true
                                                ? l + ". Present"
                                                : l + ". Absent"}
                                              <br></br>
                                            </Fragment>
                                          ))}
                                        </Box>
                                      </Fragment>
                                    ) : (
                                      <Fragment></Fragment>
                                    )}

                                    <br></br>
                                  </Fragment>
                                ))}
                              </Collapse>
                            </Box>
                            <br></br>
                          </Fragment>
                        ))}
                      </Fragment>
                    ))}
                    <br></br>
                  </Fragment>
                ))}
                <br></br>
              </Fragment>
            );
          } else if (type == 10) {
            return (
              <Fragment>
                {results.map((result, x) => (
                  <Fragment>
                    <b style={{ fontSize: "25px" }}>
                      {Object.keys(result)[0].split("#")[1]}
                    </b>
                    <br></br>
                    <span style={{ fontSize: "20px" }}>
                      Attendance
                      {" " +
                        result[Object.keys(result)[0]]["attendance_percent"]}
                      %
                    </span>
                    <br></br>
                    <br></br>
                    {Object.keys(
                      result[Object.keys(result)[0]]["subjects"]
                    ).map((b, j) => (
                      <Fragment>
                        <Box
                          p={2}
                          style={{
                            minHeight: "20px",
                            borderRadius: "6px",
                            backgroundColor: "#d7ecff",
                            color: "black",
                          }}
                        >
                          <b style={{ fontSize: "18px" }}>{b}</b>
                          <Grid container>
                            <Grid item xs={11}>
                              Total lectures:
                              {" " +
                                result[Object.keys(result)[0]]["subjects"][b][
                                  "total"
                                ]}
                              <br></br>
                              Attended:
                              {" " +
                                result[Object.keys(result)[0]]["subjects"][b][
                                  "attended"
                                ]}
                            </Grid>
                            <Grid item xs={1}>
                              <IconButton
                                style={{
                                  backgroundColor: "#3182bd",
                                  color: "white",
                                }}
                                onClick={handleExpandClick}
                                aria-expanded={expanded}
                                aria-label="show more"
                                size="large"
                              >
                                {expanded ? (
                                  <ExpandLessIcon />
                                ) : (
                                  <ExpandMoreIcon />
                                )}
                              </IconButton>
                            </Grid>
                          </Grid>
                          <br></br>
                          <Collapse in={expanded} timeout="auto" unmountOnExit>
                            {Object.keys(
                              result[Object.keys(result)[0]]["attendance"]
                            ).map((c, k) => (
                              <Fragment>
                                {Object.keys(
                                  result[Object.keys(result)[0]]["attendance"][
                                    c
                                  ]
                                ).includes(b) ? (
                                  <Fragment>
                                    <Box
                                      p={1}
                                      style={{
                                        minHeight: "10px",
                                        borderRadius: "6px",
                                        backgroundColor: "#ffffff",
                                        color: "black",
                                      }}
                                    >
                                      <b>
                                        {moment(c, "DD-MM-YYYY").format(
                                          "Do MMMM, YYYY"
                                        )}
                                      </b>
                                      <br></br>
                                      {Object.keys(
                                        result[Object.keys(result)[0]][
                                          "attendance"
                                        ][c][b]
                                      ).map((d, l) => (
                                        <Fragment>
                                          {d}.&nbsp;
                                          {result[Object.keys(result)[0]][
                                            "attendance"
                                          ][c][b][d] == true
                                            ? l + ". Present"
                                            : l + ". Absent"}
                                          <br></br>
                                        </Fragment>
                                      ))}
                                    </Box>
                                  </Fragment>
                                ) : (
                                  <Fragment></Fragment>
                                )}

                                <br></br>
                              </Fragment>
                            ))}
                          </Collapse>
                        </Box>
                        <br></br>
                      </Fragment>
                    ))}
                  </Fragment>
                ))}
              </Fragment>
            );
          } else if (type == 7) {
            return (
              <div style={{ height: "100%" }}>
                {results.map((result, x) => (
                  <div
                    style={{
                      borderBottom: "1px solid #ddd",
                      paddingBottom: "5px",
                      paddingTop: "5px",
                      marginBottom: x != 0 && x % 25 == 0 ? "100px" : 0,
                    }}
                  >
                    <div style={{ display: "flex" }}>
                      <div style={{ width: "500px", paddingLeft: "10px" }}>
                        {Object.keys(result)[0].split("#")[1]}
                      </div>
                      <div style={{ display: "flex" }}>
                        {Object.entries(Object.values(result)[0]).map(
                          ([key, value]) => (
                            <span
                              style={{
                                marginRight: "25px",
                                color: value == true ? "green" : "red",
                              }}
                            >
                              &nbsp;
                              <b>{value == true ? "P" : "A"}</b>
                              <br></br>
                            </span>
                          )
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            );
          } else if (type == 11) {
            var result = results[currentSemester];

            var datelist = getDaysArray(2024, month);
            var reso = [];
            for (var i = 0; i < result.length; i++) {
              var res = {};
              var resmail = Object.keys(result[i])[0];
              var reslist = [];
              var atkeys = Object.keys(result[i][resmail]["attendance"]);

              for (var k = 0; k < datelist.length; k++) {
                if (atkeys.includes(datelist[k].split("#")[0])) {
                  var subkeys = Object.keys(
                    result[i][resmail]["attendance"][datelist[k].split("#")[0]]
                  );
                  var resPresent = " ";
                  for (var l = 0; l < subkeys.length; l++) {
                    if (subkeys[l] == currentSubject) {
                      resPresent = JSON.stringify(
                        Object.values(
                          result[i][resmail]["attendance"][
                            datelist[k].split("#")[0]
                          ][currentSubject]
                        )
                      );
                    }
                  }
                  reslist.push(resPresent);
                } else {
                  reslist.push(" ");
                }
              }
              res[resmail] = reslist;
              reso.push(res);
            }

            function getColor(index) {
              if (index < 6) {
                return "#f4dee6";
              } else if (index < 12) {
                return "#f6d4be";
              } else if (index < 18) {
                return "#f4f0c8";
              } else if (index < 24) {
                return "#e2e5d0";
              } else {
                return "#d5e9e7";
              }
            }
            if (reso.length == 0) {
              return (
                <div
                  style={{
                    marginTop: "200px",
                    height: "100px",
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <div style={{ textAlign: "center" }}>
                    <Fab
                      size="large"
                      style={{
                        border: "3px solid #aaa",
                        boxShadow: "none",
                        backgroundColor: "transparent",
                      }}
                      aria-label="add"
                    >
                      <PriorityHighIcon
                        style={{
                          color: "grey",
                        }}
                      />
                    </Fab>
                    <br />
                    <h4 style={{ color: "grey" }}>No results.</h4>
                  </div>
                </div>
              );
            } else {
              return (
                <div style={{ transform: "scale(0.6)" }}>
                  <div
                    style={{
                      display: "flex",
                      marginTop: "-350px",
                      // marginLeft: "-300px",
                    }}
                  >
                    <div
                      style={{
                        minWidth: "200px",
                        height: "50px",
                        backgroundColor: "#b1aed8",
                        border: "1px solid grey",
                      }}
                    ></div>

                    {datelist.map((dat, index) => (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "50px",
                          minWidth: "40px",
                          border: "1px solid grey",
                          backgroundColor: getColor(index),
                        }}
                      >
                        <div> {dat.split("#")[1]}</div>
                        <b> {dat.split("#")[0].split("-")[0]}</b>
                      </div>
                    ))}
                  </div>
                  {reso.map((res, index) => (
                    <div
                      style={{
                        display: "flex",
                        // marginLeft: "-300px",
                        marginBottom:
                          index != 0 && index % 28 == 0 ? "150px" : 0,
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          minWidth: "200px",
                          height: "50px",
                          border: "1px solid grey",
                          alignItems: "center",
                          paddingLeft: "10px",
                        }}
                      >
                        {Object.keys(res)[0].split("#")[1]}
                      </div>
                      {Object.values(res)[0].map((res1, ind1) => (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "50px",
                            minWidth: "40px",
                            border: "1px solid grey",
                            backgroundColor: getColor(ind1),
                          }}
                        >
                          {res1
                            .substring(1, res1.length - 1)
                            .replaceAll("true", "P")
                            .replaceAll("false", "A")}
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
              );
            }
          } else {
            return (
              <Fragment>
                <Box
                  p={2}
                  style={{
                    minHeight: "20px",
                    borderRadius: "20px",
                    backgroundColor: "#d7ecff",
                    color: "black",
                  }}
                >
                  <Grid container spacing={3}>
                    <Grid item md={10}>
                      <div style={{ fontSize: "18px" }}>
                        <b>
                          {type}
                          {/* {attendance["attendance"][dateFormatted] == null
                            ? ""
                            : attendance["attendance"][dateFormatted][
                                currentSubjectId
                              ]["subject_name"]} */}
                        </b>
                        <div style={{ height: 10 }}></div>
                        {/* {attendance["attendance"][dateFormatted] == null
                          ? ""
                          : Object.entries(
                              attendance["attendance"][dateFormatted][
                                currentSubjectId
                              ]["attendance"]
                            ).map(([key, value]) => (
                              <span>
                                {key}. &nbsp;
                                <b>{value == true ? "Present" : "Absent"}</b>
                                <br></br>
                              </span>
                            ))} */}
                      </div>
                    </Grid>
                  </Grid>
                </Box>
              </Fragment>
            );
          }
        }
      }
    }
  }

  if (!state.auth.isLoading) {
    if (state.auth.isAuthenticated) {
      if (state.auth.user.is_manager) {
        if (moment(state.auth.expiry).utc().isAfter(moment())) {
          if (state.auth.initiated && state.auth.finalized) {
            if (isLoading || !isReady) {
              return (
                <Fragment>
                  <div
                    style={{
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      justifyContent: "center",
                      backgroundColor: "white",
                      marginTop: "46vh",
                    }}
                  >
                    <CircularProgress />
                  </div>
                </Fragment>
              );
            } else {
              return (
                <Fragment>
                  <Sidebar />
                  <div
                    style={{
                      margin: "67px 0px 0px 90px",
                      backgroundColor: "white",
                    }}
                  >
                    {selectedPageContent()}
                  </div>
                </Fragment>
              );
            }
          } else {
            return (
              <Fragment>
                <Sidebar />
                <div
                  style={{
                    margin: "67px 0px 0px 90px",
                    backgroundColor: "white",
                  }}
                >
                  <h4>Please finalize class structure by adding subjects</h4>
                </div>
              </Fragment>
            );
          }
        } else {
          return <Navigate to="/expired" />;
        }
      } else {
        createAlert("ERROR", "Permission Denied.", "Please Login as Principal");
        dispatch({
          type: "AUTH_ERROR",
        });
        return <Navigate to="/login" />;
      }
    } else {
      return <Navigate to="/login" />;
    }
  } else {
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          backgroundColor: "white",
          marginTop: "46vh",
        }}
      >
        <CircularProgress />
      </div>
    );
  }
}

export default Attendance;
