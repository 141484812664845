import { useState, Fragment, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Sidebar from "../components/Sidebar";
import Box from "@mui/material/Box";
import { API_URL, alertMessage } from "../actions/auth";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import axios from "axios";
import GetAppIcon from "@mui/icons-material/GetApp";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CircularProgress from "@mui/material/CircularProgress";
import { Link, Navigate, useNavigate } from "react-router-dom";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { Line } from "react-chartjs-2";
import { Chart } from "chart.js/auto";
import ButtonBase from "@mui/material/ButtonBase";
import moment from "moment";

function Ranking() {
  const dispatch = useDispatch();
  function createAlert(type, title, message) {
    dispatch(alertMessage(type, title, message));
  }
  const navigate = useNavigate();

  const params = new URLSearchParams(window.location.search);
  const state = useSelector((state) => state);

  const [selectedPage, setSelectedPage] = useState("SELECT");
  const [index, setIndex] = useState(0);
  const [studentIndex, setStudentIndex] = useState(0);
  const [isReady, setIsReady] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingRanking, setIsLoadingRanking] = useState(false);
  const [ranking, setRanking] = useState([]);
  const [classList, setClassList] = useState([]);
  const [classs, setClass] = useState("");
  const [division, setDivision] = useState("");
  const [semester, setSemester] = useState("");
  const [studentList, setStudentList] = useState([]);

  useEffect(() => {
    if (state.auth.isLoading) {
      dispatch({ type: "ENTRY_PAGE", payload: window.location.pathname });
      navigate("/", { replace: true });
    } else {
      var body = {};
      const config = {
        headers: {
          Authorization: state.auth.token,
          "X-CSRFToken": state.auth.csrfToken,
          "Content-Type": "application/json",
        },
      };
      body["institution_id"] = state.auth.user.institution_id;

      body = JSON.stringify(body);
      axios.post(API_URL + "/get_class_list", body, config).then((res) => {
        var classListTemp = res.data;
        setClassList(classListTemp);
        setClass(Object.keys(classListTemp)[0]);
        setDivision(
          Object.keys(classListTemp[Object.keys(classListTemp)[0]])[0]
        );
        setSemester(
          Object.keys(
            classListTemp[Object.keys(classListTemp)[0]][
              Object.keys(classListTemp[Object.keys(classListTemp)[0]])[0]
            ]
          )[0]
        );
        setIsReady(true);
        getData(
          Object.keys(classListTemp)[0],
          Object.keys(classListTemp[Object.keys(classListTemp)[0]])[0]
        );
      });
    }
  }, []);

  function getLineData() {
    if (Object.keys(ranking).length != 0) {
      var tempRanking = ranking;
      console.log(tempRanking);
      var tempChartX = [];
      var tempChartY = [];
      for (
        var i = 0;
        i < tempRanking[state.auth.current_year]["history"].length;
        i++
      ) {
        tempChartX.push(0);
        tempChartY.push(0);
        tempChartX.push(
          tempRanking[state.auth.current_year]["history"][i]["points"]
        );
        tempChartY.push(i + 1);
        console.log(tempChartX, tempChartY);
      }
      return { x: tempChartX, y: tempChartY };
    } else {
      return { x: [0, 0], y: [0, 0] };
    }
  }

  if (state.auth.refresh == "REFRESH_DATA") {
    getData(classs, division);
    dispatch({ type: "REFRESH_DATA", payload: null });
  }

  function getClassList() {
    var classes = Object.keys(classList).map((classs, index) => (
      <MenuItem value={classs}>{classs}</MenuItem>
    ));
    return classes;
  }

  function getDivisionList() {
    var classes = Object.keys(classList[classs]).map((division, index) => (
      <MenuItem value={division}>{division}</MenuItem>
    ));
    return classes;
  }

  function getSemesterList() {
    var semesters = Object.keys(classList[classs][division]).map(
      (semester, index) => <MenuItem value={semester}>{semester}</MenuItem>
    );
    return semesters;
  }

  function getRanking(email) {
    var body = {};
    const config = {
      headers: {
        Authorization: state.auth.token,
        "X-CSRFToken": state.auth.csrfToken,
        "Content-Type": "application/json",
      },
    };

    body["email"] = email;
    body = JSON.stringify(body);

    axios.post(API_URL + "/get_rank_history", body, config).then((res) => {
      setIsLoadingRanking(false);
      setRanking(res.data);
    });
  }

  function handlePageChange(index) {
    setStudentIndex(index);
    setSelectedPage("RESULT");
  }

  function getData(cla, div) {
    setSelectedPage("STUDENTS");
    var body = {};
    const config = {
      headers: {
        Authorization: state.auth.token,
        "X-CSRFToken": state.auth.csrfToken,
        "Content-Type": "application/json",
      },
    };

    body["class_id"] = cla;
    body["division"] = div;
    body["institution_id"] = state.auth.user.institution_id;

    body = JSON.stringify(body);

    axios
      .post(API_URL + "/get_students_list_class_division", body, config)
      .then((res) => {
        setStudentList(res.data);
      });
  }

  function handleSemesterChange(e) {
    setSemester(e.target.value);
  }

  function handleClassChange(e) {
    setClass(e.target.value);
    getData(e.target.value, division);
  }

  function handleDivisionChange(e) {
    setDivision(e.target.value);
    getData(classs, e.target.value);
  }

  function getContent() {
    if (selectedPage == "STUDENTS") {
      return (
        <Fragment>
          <Grid container spacing={1.5}>
            <Grid item md={9}>
              <Grid container spacing={1.5} style={{ marginTop: "-17px" }}>
                {studentList.map((student, index) => (
                  <Grid item md={6}>
                    <CardActionArea
                      sx={[
                        {
                          borderRadius: "6px",
                          border: "1.5px solid #d7ecff",
                          transition:
                            "background-color 0.5s, border-color 0.5s",
                        },
                        (theme) => ({
                          "&:hover": {
                            backgroundColor: "#ebf5ff",
                            borderColor: "#3182bd",
                          },
                          ".MuiCardActionArea-focusHighlight": {
                            background: "transparent",
                          },

                          "&& .MuiTouchRipple-child": {
                            backgroundColor: "#084594",
                          },
                        }),
                      ]}
                      style={{
                        padding: "10px",
                        borderRadius: "6px",
                        marginBottom: "5px",
                        border: "2px solid #d7ecff",
                        borderRadius: "6px",
                        cursor: "pointer",
                        height: "100%",
                      }}
                      onClick={() => {
                        setSelectedPage("RANKING");
                        setIsLoadingRanking(true);
                        setIndex(index);
                      }}
                    >
                      <Grid container justifyContent="space-between">
                        <Grid item md={10}>
                          <b
                            style={{
                              fontSize: "20px",
                              fontFamily: "Lato-Bold",
                              color: "#084594",
                            }}
                          >
                            {student["name"]}
                          </b>
                          <br></br>
                          <span
                            style={{
                              color: "#3182bd",
                              fontFamily: "Lato-Regular",
                              fontSize: "16px",
                            }}
                          >
                            {" "}
                            Roll no
                            <span style={{ fontFamily: "Lato-Bold" }}>
                              {" " + student["roll_number"]}
                            </span>
                          </span>
                        </Grid>

                        <Grid item md={2}>
                          <ButtonBase
                            p={2}
                            value={index}
                            fullWidth
                            style={{
                              backgroundColor: "#31a354",
                              color: "white",
                              height: "25px",
                              width: "50px",
                              borderRadius: "2px",
                              margin: "8px 5px 0px 5px",
                            }}
                            onClick={() => {
                              setSelectedPage("RANKING");
                              setIsLoadingRanking(true);
                              setIndex(index);
                            }}
                          >
                            <b>
                              <p
                                style={{
                                  marginTop: "15px",
                                  fontFamily: "Bahnschrift",
                                  fontWeight: "lighter",
                                }}
                              >
                                VIEW
                              </p>
                            </b>
                          </ButtonBase>
                        </Grid>
                      </Grid>
                    </CardActionArea>
                  </Grid>
                ))}
              </Grid>
            </Grid>
            <Grid item xs={3} style={{ marginTop: "-12px" }}>
              <span
                style={{
                  color: "#3182bd",
                  fontFamily: "Lato-Regular",
                  fontSize: "14px",
                }}
              >
                Semester
              </span>
              <Select
                value={semester}
                onChange={(e) => handleSemesterChange(e)}
                style={{
                  marginTop: "2px",
                  height: "40px",
                  marginBottom: "5px",
                }}
                fullWidth
                variant={"outlined"}
              >
                {getSemesterList()}
              </Select>

              <span
                style={{
                  color: "#3182bd",
                  fontFamily: "Lato-Regular",
                  fontSize: "14px",
                }}
              >
                Class
              </span>
              <Select
                value={classs}
                onChange={(e) => handleClassChange(e)}
                style={{
                  marginTop: "2px",
                  height: "40px",
                  marginBottom: "5px",
                }}
                fullWidth
                variant={"outlined"}
              >
                {getClassList()}
              </Select>

              <span
                style={{
                  color: "#3182bd",
                  fontFamily: "Lato-Regular",
                  fontSize: "14px",
                }}
              >
                Division
              </span>
              <Select
                value={division}
                onChange={(e) => handleDivisionChange(e)}
                style={{
                  marginTop: "2px",
                  height: "40px",
                  marginBottom: "5px",
                }}
                fullWidth
                variant={"outlined"}
              >
                {getDivisionList()}
              </Select>
            </Grid>
          </Grid>
        </Fragment>
      );
    } else if (selectedPage == "RANKING") {
      if (isLoadingRanking) {
        getRanking(studentList[index]["email"]);
        return (
          <Box>
            <CircularProgress />
          </Box>
        );
      } else {
        return (
          <div style={{ marginTop: "-6px" }}>
            <div style={{ display: "flex", marginBottom: "35px" }}>
              <Button
                fullWidth
                style={{
                  backgroundColor: "#3182bd",
                  color: "white",
                  width: "5%",
                  height: "35px",
                  borderTopLeftRadius: "6px",
                  borderBottomLeftRadius: "6px",
                  borderTopRightRadius: "0px",
                  borderBottomRightRadius: "0px",
                }}
                onClick={() => setSelectedPage("STUDENTS")}
              >
                <ArrowBackIcon style={{ fontSize: "25px" }} />
              </Button>
              <div
                textAlign="center"
                style={{
                  color: "#084594",
                  backgroundColor: "#d7ecff",
                  width: "95%",
                  height: "35px",
                  padding: "3px 0px 0px 25px",
                  alignItems: "center",
                  borderTopLeftRadius: "0px",
                  borderBottomLeftRadius: "0px",
                  borderTopRightRadius: "6px",
                  borderBottomRightRadius: "6px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    marginLeft: "-10px",
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <h2 style={{ fontSize: "20px" }}>
                    Holistic Ranking data for {studentList[index]["name"]}
                  </h2>
                </div>
              </div>
            </div>
            <Box style={{ marginTop: "-30px" }}>
              <Line
                height={105}
                width={250}
                data={{
                  labels: getLineData()["y"],
                  datasets: [
                    {
                      label: "Holistic ranking",
                      fill: true,
                      lineTension: 0.3,
                      backgroundColor: "rgba(225, 204,230, .3)",
                      borderColor: "red",
                      borderCapStyle: "butt",
                      borderDash: [],
                      borderDashOffset: 0.0,
                      borderJoinStyle: "miter",
                      pointBorderColor: "red",
                      pointBackgroundColor: "red",
                      pointBorderWidth: 1,
                      pointHoverRadius: 5,
                      pointHoverBackgroundColor: "red",
                      pointHoverBorderColor: "red",
                      pointHoverBorderWidth: 2,
                      pointRadius: 1,
                      pointHitRadius: 10,
                      data: getLineData()["x"],
                    },
                  ],
                }}
                options={{ responsive: true }}
              />
            </Box>
            <h3>My Ranking History</h3>
            <Grid container spacing={2}>
              {Object.values(ranking).map((rank, index) => (
                <Grid item md={3}>
                  <Box
                    fullWidth
                    style={{
                      boxShadow: "0 2px 6px 0 rgba(0,0,0,0.2)",
                      color: "white",
                      backgroundColor: "white",
                      borderRadius: "6px",
                      minHeight: "100px",
                      padding: "15px",
                      alignItems: "center",
                      borderBottom: "3px solid #3283c9",
                    }}
                  >
                    <Box
                      style={{
                        display: "flex",
                        color: "white",
                        backgroundColor: "white",
                        borderRadius: "6px",
                        justifyContent: "center",
                      }}
                    >
                      <Box
                        textAlign={"center"}
                        style={{
                          borderRadius: "40px",
                          backgroundColor: "#3283c9",
                          width: "180px",
                          height: "50px",
                          marginTop: "10px",
                        }}
                      >
                        <div
                          style={{
                            color: "white",
                            padding: "5px",
                            marginTop: "2px",
                            fontSize: "24px",
                            textAlign: "center",
                            fontFamily: "Bahnschrift",
                            letterSpacing: "2px",
                          }}
                        >
                          {Object.keys(ranking)[index]}
                        </div>
                      </Box>
                    </Box>
                    <Box
                      style={{
                        marginTop: "10px",
                        display: "flex",
                        justifyContent: "space-between",
                        fontSize: "20px",
                        padding: "10px",
                        fontFamily: "Roboto-Regular",
                      }}
                    >
                      <span
                        style={{
                          color: "black",
                          textAlign: "left",
                          fontFamily: "Roboto-Regular",
                        }}
                      >
                        {rank["points"]} Points
                      </span>
                      <Box style={{ width: 10 }}></Box>
                      <span
                        style={{
                          color: "black",
                          textAlign: "right",
                        }}
                      >
                        Rank {rank["rank"]}
                      </span>
                    </Box>
                  </Box>
                </Grid>
              ))}
            </Grid>
            <br></br>

            <Box
              style={{
                borderTop: "1px solid #ddd",
                margin: "10px 0px 25px 0px",
              }}
            ></Box>

            {Object.keys(ranking).map((year) => (
              <Fragment>
                <span
                  style={{
                    marginTop: 30,
                    fontSize: "25px",
                    color: "black",
                    fontFamily: "Bahnschrift",
                    letterSpacing: "2px",
                  }}
                >
                  {year}
                </span>
                <br></br>
                <div
                  style={{
                    color: "grey",
                    fontSize: "16px",
                    marginTop: "-10px",
                    fontFamily: "Roboto-Regular",
                  }}
                >
                  {ranking[year]["points"]} Points, Rank {ranking[year]["rank"]}
                </div>
                <br></br>
                <br></br>

                <Grid container spacing={2}>
                  {ranking[year]["history"].map((rank) => (
                    <Grid item md={6}>
                      <Box
                        style={{
                          color: "white",
                          backgroundColor: "white",
                          borderRadius: "6px",
                          padding: "15px",
                          alignItems: "center",
                          borderRadius: "6px",
                          border: "3px solid #d7ecff",
                        }}
                      >
                        <Box
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Box>
                            <div
                              style={{
                                color: "#084594",
                                fontSize: "20px",
                                fontFamily: "Lato-Bold",
                                marginBottom: "-5px",
                              }}
                            >
                              {rank["points"]} Points
                            </div>
                            <span style={{ color: "black" }}>
                              {rank["achievement"]}
                            </span>
                          </Box>
                          <Box>
                            <Box
                              style={{
                                backgroundColor:
                                  rank["reviewed"] != null
                                    ? rank["reviewed"]
                                      ? "#31a354"
                                      : "#f44336"
                                    : "#3283c9",
                                padding: 2,
                                height: "30px",
                                width: "30px",
                                borderRadius: "30px",
                              }}
                            >
                              {rank["reviewed"] != null ? (
                                rank["reviewed"] ? (
                                  <CheckIcon
                                    style={{
                                      fontWeight: "bold",
                                      fontSize: "26px",
                                    }}
                                  />
                                ) : (
                                  <CloseIcon
                                    style={{
                                      fontWeight: "bold",
                                      fontSize: "26px",
                                    }}
                                  />
                                )
                              ) : (
                                <AccessTimeIcon
                                  style={{
                                    fontWeight: "bold",
                                    fontSize: "26px",
                                  }}
                                />
                              )}
                            </Box>
                          </Box>
                        </Box>

                        <Box style={{ height: 15 }}></Box>
                        {rank["documents"].map((document) => (
                          <div>
                            <a
                              target="_blank"
                              style={{
                                textDecoration: "none",
                              }}
                              href={document["link"]}
                            >
                              <Button
                                style={{
                                  width: "100%",
                                  backgroundColor: "white",
                                  borderRadius: "6px",
                                  marginBottom: "10px",

                                  border: "1px solid #ddd",
                                  flexDirection: "row",
                                  justifyContent: "space-between",
                                  paddingLeft: "10px",
                                  height: "35px",
                                }}
                              >
                                <span
                                  style={{
                                    marginTop: "2px",
                                    textAlignVertical: "center",
                                    color: "#3182bd",
                                  }}
                                >
                                  {document["document"]}
                                </span>

                                <Box
                                  style={{
                                    padding: "5px",
                                    height: "35px",
                                    width: "60px",
                                    backgroundColor: "#3283c9",
                                    borderTopRightRadius: "6px",
                                    borderBottomRightRadius: "6px",
                                    marginRight: "-10px",
                                  }}
                                >
                                  <div
                                    style={{
                                      color: "white",
                                      textAlign: "center",
                                      fontFamily: "Bahnschrift",
                                      marginTop: "2px",
                                    }}
                                  >
                                    VIEW
                                  </div>
                                </Box>
                              </Button>
                            </a>
                            <div
                              style={{
                                textAlignVertical: "center",
                                color: "black",
                                margin: "-10px 0px 10px 0px",
                                fontFamily: "Lato-Regular",
                                fontSize: "14px",
                              }}
                            >
                              {rank["description"]}
                            </div>
                          </div>
                        ))}
                      </Box>
                    </Grid>
                  ))}
                </Grid>
                <br></br>
                <br></br>
              </Fragment>
            ))}
          </div>
        );
      }
    }
  }

  if (!state.auth.isLoading) {
    if (state.auth.isAuthenticated) {
      if (state.auth.user.is_manager) {
        if (moment(state.auth.expiry).utc().isAfter(moment())) {
          if (isLoading) {
            return (
              <Fragment>
                <Sidebar />
                <div
                  style={{
                    maxWidth: "88vw",
                    marginTop: "80px",
                    marginLeft: "8vw",
                    backgroundColor: "white",
                    height: "100vh",
                  }}
                >
                  <Grid spacing={0} alignItems="center" justifyContent="center">
                    <Box>
                      <CircularProgress />
                    </Box>
                  </Grid>
                </div>
              </Fragment>
            );
          } else {
            return (
              <Fragment>
                <Sidebar />
                <div
                  style={{
                    margin: "70px 20px 20px 90px",
                    backgroundColor: "white",
                  }}
                >
                  {getContent()}
                </div>
              </Fragment>
            );
          }
        } else {
          return <Navigate to="/expired" />;
        }
      } else {
        createAlert("ERROR", "Permission Denied.", "Please Login as Principal");
        dispatch({
          type: "AUTH_ERROR",
        });
        return <Navigate to="/login" />;
      }
    } else {
      return <Navigate to="/login" />;
    }
  } else {
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          backgroundColor: "white",
          marginTop: "46vh",
        }}
      >
        <CircularProgress />
      </div>
    );
  }
}

export default Ranking;
