import React, { useEffect, Fragment, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Router, Route, Routes, useNavigate } from "react-router-dom";
import Login from "./pages/Login";
import Forgot from "./pages/Forgot";
import Dashboard from "./pages/Dashboard";
import Ranking from "./pages/Ranking";
import Results from "./pages/Results";
import Support from "./pages/Support";
import Messenger from "./pages/Messenger";
import Settings from "./pages/Settings";
import Events from "./pages/Events";
import Attendance from "./pages/Attendance";
import Exams from "./pages/Exams";
import Loader from "./pages/Loader";
import Feedback from "./pages/Feedback";
import Logout from "./pages/Logout";
import Approvals from "./pages/Approvals";
import Notifications from "./pages/Notifications";
import Expired from "./pages/Expired";
import Oops from "./pages/Oops";
import Initialize from "./pages/Initialize";
import AddManager from "./pages/AddManager";
import FeedbackForm from "./pages/FeedbackForm";
import ErrorReport from "./pages/ErrorReport";
import Students from "./pages/Students";

import { loadUser, TEST } from "./actions/auth";

process.env.NODE_TLS_REJECT_UNAUTHORIZED = "0";

function App() {
  const dispatch = useDispatch();
  const params = new URLSearchParams(window.location.search);
  const state = useSelector((state) => state);
  const navigate = useNavigate();

  useEffect(() => {
    dispatch({ type: "USER_LOADING" });
    dispatch(loadUser());
  }, []);

  if (state.auth.loginFailed) {
    if (
      window.location.pathname !== "/initialize" ||
      window.location.pathname === "/addmanager"
    ) {
      navigate("/login", { replace: true });
      dispatch({ type: "RESET_REDIRECT_LOGIN" });
    }
  }

  return (
    <Routes>
      <Route exact path="/" element={<Loader />} />
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/login" element={<Login />} />
      <Route path="/forgotpassword" element={<Forgot />} />
      <Route path="/ranking" element={<Ranking />} />
      <Route path="/messenger" element={<Messenger />} />
      <Route path="/settings" element={<Settings />} />
      <Route path="/support" element={<Support />} />
      <Route path="/results" element={<Results />} />
      <Route path="/events" element={<Events />} />
      <Route path="/attendance" element={<Attendance />} />
      <Route path="/exams" element={<Exams />} />
      <Route path="/logout" element={<Logout />} />
      <Route path="/approvals" element={<Approvals />} />
      <Route path="/feedback" element={<Feedback />} />
      <Route path="/notifications" element={<Notifications />} />{" "}
      <Route path="/initialize" element={<Initialize />} />{" "}
      <Route path="/addmanager" element={<AddManager />} />
      <Route path="/expired" element={<Expired />} />
      <Route path="/students" element={<Students />} />
      <Route path="/error_elemzy" element={<ErrorReport />} />
      <Route path="/feedback_elemzy" element={<FeedbackForm />} />
      <Route path="*" element={<Oops />} />
    </Routes>
  );
}

export default App;
