import { useState, Fragment, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, Navigate, useNavigate } from "react-router-dom";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import Fab from "@mui/material/Fab";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Sidebar from "../components/Sidebar";
import Box from "@mui/material/Box";
import { API_URL, alertMessage } from "../actions/auth";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import axios from "axios";
import GetAppIcon from "@mui/icons-material/GetApp";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import TextField from "@mui/material/TextField";
import DateRangeIcon from "@mui/icons-material/DateRange";
import CircularProgress from "@mui/material/CircularProgress";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import moment from "moment";
import ButtonBase from "@mui/material/ButtonBase";

const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const colorList = [
  "#e5f5f9",
  "#e0ecf4",
  "#fee8c8",
  "#ece7f2",
  "#f0f0f0",
  "#fde0dd",
  "#efedf5",
  "#e5f5e0",
  "#d7ecff",
  "#fee6ce",
];
const colorList2 = [
  "#2ca25f",
  "#8856a7",
  "#e34a33",
  "#1c9099",
  "#636363",
  "#c51b8a",
  "#756bb1",
  "#31a354",
  "#3283c9",
  "#e6550d",
];

const colorList3 = [
  "#99d8c9",
  "#9ebcda",
  "#fdbb84",
  "#a6bddb",
  "#bdbdbd",
  "#fa9fb5",
  "#bcbddc",
  "#addd8e",
  "#9ecae1",
  "#a1d99b",
];

function makeid(length) {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

function Exams() {
  const dispatch = useDispatch();
  function createAlert(type, title, message) {
    dispatch(alertMessage(type, title, message));
  }
  const navigate = useNavigate();
  const [randomText, setRandomText] = useState(makeid(5));

  const params = new URLSearchParams(window.location.search);
  const state = useSelector((state) => state);
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);

  var newExamDict = {};
  newExamDict[""] = {};
  newExamDict[Object.keys(newExamDict)[0]]["marks_total"] = "";
  newExamDict[Object.keys(newExamDict)[0]]["date"] = new Date();
  newExamDict[Object.keys(newExamDict)[0]]["time_start"] = new Date();
  newExamDict[Object.keys(newExamDict)[0]]["time_end"] = new Date();
  newExamDict[Object.keys(newExamDict)[0]]["id"] = makeid(5);
  newExamDict[Object.keys(newExamDict)[0]][
    "showDatePicker" + newExamDict[Object.keys(newExamDict)[0]]["id"]
  ] = false;
  newExamDict[Object.keys(newExamDict)[0]][
    "showTimeStartPicker" + newExamDict[Object.keys(newExamDict)[0]]["id"]
  ] = false;
  newExamDict[Object.keys(newExamDict)[0]][
    "showTimeEndPicker" + newExamDict[Object.keys(newExamDict)[0]]["id"]
  ] = false;

  const [newExam, setNewExam] = useState([newExamDict]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  function addNewExamDict() {
    var newExamSubject = {};
    newExamSubject[""] = {};
    newExamSubject[Object.keys(newExamSubject)[0]]["marks_total"] = "";
    newExamSubject[Object.keys(newExamSubject)[0]]["date"] = new Date();
    newExamSubject[Object.keys(newExamSubject)[0]]["time_start"] = new Date();
    newExamSubject[Object.keys(newExamSubject)[0]]["time_end"] = new Date();
    newExamSubject[Object.keys(newExamSubject)[0]]["id"] = makeid(5);
    newExamSubject[Object.keys(newExamSubject)[0]][
      "showDatePicker" + newExamSubject[Object.keys(newExamSubject)[0]]["id"]
    ] = false;
    newExamSubject[Object.keys(newExamSubject)[0]][
      "showTimeStartPicker" +
        newExamSubject[Object.keys(newExamSubject)[0]]["id"]
    ] = false;
    newExamSubject[Object.keys(newExamSubject)[0]][
      "showTimeEndPicker" + newExamSubject[Object.keys(newExamSubject)[0]]["id"]
    ] = false;
    newExam[newExam.length] = newExamSubject;
    setRandomText(makeid(5));
    setNewExam(newExam);
  }

  function removeNewExamDict(index) {
    if (newExam.length !== 1) {
      newExam.splice(index, 1);
      setRandomText(makeid(5));
      setNewExam(newExam);
    }
  }

  function onChangeNewSubjectMarks(value, index) {
    var oldSubjectName = Object.keys(newExam[index])[0];
    var oldExamDict = newExam;
    oldExamDict[index][oldSubjectName]["marks_total"] = value;
    setRandomText(makeid(5));
    setNewExam(oldExamDict);
  }

  function onChangeNewSubjectText(value, index) {
    var newExamSubject = newExam[index][Object.keys(newExam[index])[0]];
    var oldSubjectName = Object.keys(newExam[index])[0];
    var oldExamDict = newExam;
    delete oldExamDict[index][oldSubjectName];
    oldExamDict[index][value] = newExamSubject;
    setRandomText(makeid(5));
    setNewExam(oldExamDict);
  }

  const onChangeSubjectDate = (date, index, id) => {
    var oldSubjectName = Object.keys(newExam[index])[0];
    var oldExamDict = newExam;
    oldExamDict[index][oldSubjectName]["showDatePicker" + id] = false;
    oldExamDict[index][oldSubjectName]["date"] = date;

    setRandomText(makeid(5));
    setNewExam(oldExamDict);
  };

  const onChangeSubjectTimeStart = (time, index, id) => {
    var oldSubjectName = Object.keys(newExam[index])[0];
    var oldExamDict = newExam;
    oldExamDict[index][oldSubjectName]["showTimeStartPicker" + id] = false;
    oldExamDict[index][oldSubjectName]["time_start"] = time;

    setRandomText(makeid(5));
    setNewExam(oldExamDict);
  };

  const onChangeSubjectTimeEnd = (time, index, id) => {
    var oldSubjectName = Object.keys(newExam[index])[0];
    var oldExamDict = newExam;
    oldExamDict[index][oldSubjectName]["showTimeEndPicker" + id] = false;
    oldExamDict[index][oldSubjectName]["time_end"] = time;

    setRandomText(makeid(5));
    setNewExam(oldExamDict);
  };

  function getTimeInWords(time) {
    var ampm, hour, minutes, fullTime;

    hour = time.getHours();

    if (hour <= 11) {
      ampm = "AM";
    } else {
      ampm = "PM";
    }

    if (hour > 12) {
      hour = hour - 12;
    }

    if (hour == 0) {
      hour = 12;
    }

    minutes = time.getMinutes();

    if (minutes < 10) {
      minutes = "0" + minutes.toString();
    }

    fullTime =
      hour.toString() + ":" + minutes.toString() + " " + ampm.toString();

    return fullTime;
  }

  function deleteExam() {
    var body = {};
    const config = {
      headers: {
        Authorization: state.auth.token,
        "X-CSRFToken": state.auth.csrfToken,
        "Content-Type": "application/json",
      },
    };
    console.log(classs);
    body["exam_id"] = exams[selectedExam]["id"];
    body["class_id"] = classs;
    body["division"] = division;
    body["semester"] = semester;

    body["institution_id"] = state.auth.user.institution_id;
    body = JSON.stringify(body);

    axios
      .post(API_URL + "/delete_exam", body, config)
      .then((res) => {
        setSelectedPage("EXAMS");
        getExams(classs, division);
        setOpen2(false);
        createAlert("SUCCESS", "Success.", "Exam deleted.");
      })
      .catch((err) => {
        createAlert("ERROR", "Error", "An error occured.");
      });
  }

  function addExam() {
    if (!isSubmitting) {
      if (examName.length == 0) {
        setOpen(false);
        createAlert("ERROR", "Error.", "Please add exam name.");
      } else {
        setIsSubmitting(true);
        const config = {
          headers: {
            Authorization: state.auth.token,
            "X-CSRFToken": state.auth.csrfToken,
            "Content-Type": "application/json",
          },
        };

        var exam = {};
        exam["id"] = makeid(5);
        exam["test"] = examName;
        exam["start_date"] = moment(
          newExam[0][Object.keys(newExam[0])[0]]["date"]
        ).format("DD-MM-YYYY");
        exam["end_date"] = moment(
          newExam[newExam.length - 1][
            Object.keys(newExam[newExam.length - 1])[0]
          ]["date"]
        ).format("DD-MM-YYYY");

        var marks_total = 0;
        var examDict = newExam;

        for (var i = 0; i < newExam.length; i++) {
          examDict[i][Object.keys(examDict[i])[0]]["date"] = moment(
            examDict[i][Object.keys(examDict[i])[0]]["date"]
          ).format("DD-MM-YYYY");

          var timeInWords =
            getTimeInWords(
              newExam[i][Object.keys(newExam[i])[0]]["time_start"]
            ) +
            " to " +
            getTimeInWords(newExam[i][Object.keys(newExam[i])[0]]["time_end"]);

          examDict[i][Object.keys(examDict[i])[0]]["time"] = timeInWords;

          delete examDict[i][Object.keys(examDict[i])[0]][
            "showDatePicker" + examDict[i][Object.keys(examDict[i])[0]]["id"]
          ];
          delete examDict[i][Object.keys(examDict[i])[0]][
            "showTimeStartPicker" +
              examDict[i][Object.keys(examDict[i])[0]]["id"]
          ];
          delete examDict[i][Object.keys(examDict[i])[0]][
            "showTimeEndPicker" + examDict[i][Object.keys(examDict[i])[0]]["id"]
          ];
          delete examDict[i][Object.keys(examDict[i])[0]]["id"];
          delete examDict[i][Object.keys(examDict[i])[0]]["time_start"];
          delete examDict[i][Object.keys(examDict[i])[0]]["time_end"];

          marks_total += parseInt(
            newExam[i][Object.keys(newExam[i])[0]]["marks_total"]
          );
        }

        exam["subjects"] = examDict;
        exam["marks_total"] = marks_total.toString();
        exam["completed"] = false;
        exam["semester"] = semester;

        var body = {};

        body["semester"] = semester;
        body["class_id"] = classs;
        body["division"] = division;
        body["exam"] = exam;
        body["email"] = state.auth.user.email;

        body["institution_id"] = state.auth.user.institution_id;
        body = JSON.stringify(body);

        axios
          .post(API_URL + "/add_exam", body, config)
          .then((res) => {
            setIsReady(false);
            var newExamDict = {};
            newExamDict[""] = {};
            newExamDict[Object.keys(newExamDict)[0]]["marks_total"] = "";
            newExamDict[Object.keys(newExamDict)[0]]["date"] = new Date();
            newExamDict[Object.keys(newExamDict)[0]]["time_start"] = new Date();
            newExamDict[Object.keys(newExamDict)[0]]["time_end"] = new Date();
            newExamDict[Object.keys(newExamDict)[0]]["id"] = makeid(5);
            newExamDict[Object.keys(newExamDict)[0]][
              "showDatePicker" + newExamDict[Object.keys(newExamDict)[0]]["id"]
            ] = false;
            newExamDict[Object.keys(newExamDict)[0]][
              "showTimeStartPicker" +
                newExamDict[Object.keys(newExamDict)[0]]["id"]
            ] = false;
            newExamDict[Object.keys(newExamDict)[0]][
              "showTimeEndPicker" +
                newExamDict[Object.keys(newExamDict)[0]]["id"]
            ] = false;
            setIsSubmitting(false);
            setNewExam([newExamDict]);
            setOpen(false);
            setIsReady(true);
            getExams(classs, division);
            createAlert("SUCCESS", "Success.", "Exam added.");
          })
          .catch((err) => {
            createAlert("ERROR", "Error", "An error occured.");
          });
      }
    }
  }

  const [examName, setExamName] = useState("");
  const [selectedPage, setSelectedPage] = useState("EXAMS");
  const [isLoading, setIsLoading] = useState(false);
  const [exams, setExams] = useState([]);
  const [classList, setClassList] = useState([]);
  const [classs, setClass] = useState("");
  const [division, setDivision] = useState("");
  const [semester, setSemester] = useState("");
  const [isReady, setIsReady] = useState(false);
  const [selectedExam, setSelectedExam] = useState(0);
  const [examsPage, setExamsPage] = useState("UPCOMING");

  function getClassList() {
    var classes = Object.keys(classList).map((classs, index) => (
      <MenuItem value={classs}>{classs}</MenuItem>
    ));
    return classes;
  }

  function getDivisionList() {
    var classes = Object.keys(classList[classs]).map((division, index) => (
      <MenuItem value={division}>{division}</MenuItem>
    ));
    return classes;
  }

  function getSemesterList() {
    var semesters = Object.keys(classList[classs][division]).map(
      (semester, index) => <MenuItem value={semester}>{semester}</MenuItem>
    );
    return semesters;
  }

  function getExams(cla, div) {
    const config = {
      headers: {
        Authorization: state.auth.token,
        "X-CSRFToken": state.auth.csrfToken,
        "Content-Type": "application/json",
      },
    };

    var body = {};
    body["class_id"] = cla;
    body["division"] = div;
    body["institution_id"] = state.auth.user.institution_id;
    body = JSON.stringify(body);

    axios
      .post(API_URL + "/get_exams", body, config)
      .then((res) => {
        setExams(res.data);
        setIsLoading(false);
      })
      .catch((err) => {});
  }

  useEffect(() => {
    if (state.auth.isLoading) {
      dispatch({ type: "ENTRY_PAGE", payload: window.location.pathname });
      navigate("/", { replace: true });
    } else {
      var body = {};
      const config = {
        headers: {
          Authorization: state.auth.token,
          "X-CSRFToken": state.auth.csrfToken,
          "Content-Type": "application/json",
        },
      };
      body["institution_id"] = state.auth.user.institution_id;

      body = JSON.stringify(body);
      axios.post(API_URL + "/get_class_list", body, config).then((res) => {
        var classListTemp = res.data;
        setClassList(classListTemp);
        setClass(Object.keys(classListTemp)[0]);
        setDivision(
          Object.keys(classListTemp[Object.keys(classListTemp)[0]])[0]
        );
        setSemester(
          Object.keys(
            classListTemp[Object.keys(classListTemp)[0]][
              Object.keys(classListTemp[Object.keys(classListTemp)[0]])[0]
            ]
          )[0]
        );
        setIsReady(true);
        getExams(
          Object.keys(classListTemp)[0],
          Object.keys(classListTemp[Object.keys(classListTemp)[0]])[0]
        );
      });
    }
  }, []);

  if (state.auth.refresh == "REFRESH_DATA") {
    getExams(classs, division);
    dispatch({ type: "REFRESH_DATA", payload: null });
  }

  function handleSemesterChange(e) {
    setSemester(e.target.value);
    getExams(classs, division);
  }

  function handleClassChange(e) {
    console.log(classList);
    setClass(e.target.value);
    setSemester(Object.keys(classList[e.target.value][division])[0]);
    getExams(e.target.value, division);
  }

  function handleDivisionChange(e) {
    setDivision(e.target.value);
    getExams(classs, e.target.value);
  }

  function getContent() {
    console.log(semester);
    if (isLoading) {
      return (
        <Fragment>
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              backgroundColor: "white",
              marginTop: "46vh",
            }}
          >
            <CircularProgress />
          </div>
        </Fragment>
      );
    } else {
      if (examsPage == "UPCOMING") {
        if (selectedPage == "EXAMS") {
          var examCount = 0;

          return (
            <div style={{ marginTop: "-30px" }}>
              <Grid container spacing={1.5}>
                {exams.map((exam, index) => (
                  <Fragment>
                    {moment(exam["end_date"], "DD-MM-YYYY").isAfter(
                      moment()
                    ) ? (
                      <Grid item md={4}>
                        <span style={{ display: "none" }}>
                          {(examCount += 1)}
                        </span>
                        <Card
                          style={{
                            minHeight: "100%",
                            backgroundColor: "white",
                            boxShadow: "none",
                          }}
                          onClick={(e) => {
                            setSelectedExam(index);
                            setSelectedPage("EXAM");
                          }}
                        >
                          <CardActionArea
                            sx={[
                              {
                                borderRadius: "6px",
                                border: "1.5px solid #d7ecff",
                                transition:
                                  "background-color 0.5s, border-color 0.5s",
                              },
                              (theme) => ({
                                "&:hover": {
                                  backgroundColor: "#ebf5ff",
                                  borderColor: "#3182bd",
                                },
                                ".MuiCardActionArea-focusHighlight": {
                                  background: "transparent",
                                },

                                "&& .MuiTouchRipple-child": {
                                  backgroundColor: "#084594",
                                },
                              }),
                            ]}
                            style={{ padding: "8px 7px 12px 13px" }}
                          >
                            <h2
                              style={{
                                marginTop: "0px",
                                color: "#084594",

                                fontFamily: "Lato-Bold",
                              }}
                            >
                              {exam["test"]}
                            </h2>

                            <p
                              style={{
                                margin: "-18px 0px 15px 1px",
                                fontSize: "14px",
                                fontFamily: "Lato-Regular",
                                color: "#3182bd",
                              }}
                            >
                              {exam["semester"]}
                            </p>
                            <div
                              style={{
                                display: "flex",
                                width: "100%",
                                justifyContent: "space-between",
                              }}
                            >
                              <div
                                style={{
                                  width: "100%",
                                }}
                              >
                                <span
                                  style={{
                                    width: "20%",
                                    fontFamily: "Lato-Bold",
                                    color: "#084594",
                                  }}
                                >
                                  Start:{" "}
                                </span>
                                <span
                                  style={{
                                    margin: "0px 0px 0px 5px",
                                    fontSize: "14px",
                                    fontFamily: "Lato-Regular",
                                    width: "80%",
                                    color: "#3182bd",
                                  }}
                                >
                                  {moment(
                                    exam["start_date"],
                                    "DD-MM-YYYY"
                                  ).format("Do MMMM, YYYY")}
                                </span>
                                <div style={{ display: "flex", width: "100%" }}>
                                  <span
                                    style={{
                                      width: "15%",
                                      color: "#084594",

                                      fontFamily: "Lato-Bold",
                                    }}
                                  >
                                    End:{" "}
                                  </span>
                                  <span
                                    style={{
                                      margin: "0px 0px 0px 5px",
                                      fontSize: "14px",
                                      fontFamily: "Lato-Regular",
                                      width: "80%",
                                      color: "#3182bd",
                                    }}
                                  >
                                    {moment(
                                      exam["end_date"],
                                      "DD-MM-YYYY"
                                    ).format("Do MMMM, YYYY")}
                                  </span>
                                </div>
                              </div>

                              <ButtonBase
                                p={2}
                                value={index}
                                fullWidth
                                onClick={(e) => {
                                  setOpen2(true);
                                  setSelectedExam(index);
                                }}
                              >
                                <DeleteIcon
                                  style={{
                                    color: "#ff3333",
                                  }}
                                />
                              </ButtonBase>
                            </div>
                          </CardActionArea>
                        </Card>
                      </Grid>
                    ) : (
                      <Fragment></Fragment>
                    )}
                  </Fragment>
                ))}
                {examCount == 0 ? (
                  <div
                    style={{
                      marginTop: "200px",
                      height: "100px",
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <div style={{ textAlign: "center" }}>
                      <Fab
                        size="large"
                        style={{
                          border: "3px solid #aaa",
                          boxShadow: "none",
                          backgroundColor: "transparent",
                        }}
                        aria-label="add"
                      >
                        <PriorityHighIcon
                          style={{
                            color: "grey",
                          }}
                        />
                      </Fab>
                      <br />
                      <h4 style={{ color: "grey" }}>No upcoming Exams.</h4>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </Grid>
            </div>
          );
        } else if (selectedPage == "EXAM") {
          return (
            <div style={{ marginTop: "-15px" }}>
              <div style={{ display: "flex", marginBottom: "15px" }}>
                <Button
                  fullWidth
                  style={{
                    backgroundColor: "#3182bd",
                    color: "white",
                    width: "5%",
                    height: "35px",
                    borderTopLeftRadius: "6px",
                    borderBottomLeftRadius: "6px",
                    borderTopRightRadius: "0px",
                    borderBottomRightRadius: "0px",
                  }}
                  onClick={() => setSelectedPage("EXAMS")}
                >
                  <ArrowBackIcon style={{ fontSize: "25px" }} />
                </Button>
                <div
                  textAlign="center"
                  style={{
                    color: "#084594",
                    backgroundColor: "#d7ecff",
                    width: "95%",
                    height: "35px",
                    padding: "3px 0px 0px 25px",
                    alignItems: "center",
                    borderTopLeftRadius: "0px",
                    borderBottomLeftRadius: "0px",
                    borderTopRightRadius: "6px",
                    borderBottomRightRadius: "6px",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      marginLeft: "-10px",
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <h2 style={{ fontSize: "20px" }}>
                      {exams[selectedExam]["test"] + " - "}
                      {exams[selectedExam]["semester"]}
                    </h2>
                    <div
                      style={{
                        fontSize: "20px",
                        fontWeight: "bold",
                        fontFamily: "Bahnschrift",
                        fontWeight: "lighter",
                        marginTop: "17px",
                      }}
                    >
                      {moment(
                        exams[selectedExam]["start_date"],
                        "DD-MM-YYYY"
                      ).format("Do MMMM, YYYY") + " "}
                      to
                      {" " +
                        moment(
                          exams[selectedExam]["end_date"],
                          "DD-MM-YYYY"
                        ).format("Do MMMM, YYYY")}
                    </div>
                  </div>
                </div>
              </div>
              <Grid container spacing={1.5}>
                {exams[selectedExam]["subjects"].map((subject, index) => (
                  <Grid item xs={4}>
                    <Card
                      style={{
                        minHeight: "100%",
                        backgroundColor: "white",
                        boxShadow: "none",
                        padding: "8px 12px 10px 12px",
                        border: "1.5px solid #d7ecff",
                        borderRadius: "6px",
                      }}
                    >
                      <div
                        style={{
                          fontSize: "20px",
                          fontWeight: "bold",
                          fontFamily: "Lato-Bold",
                          marginBottom: "0px",
                          color: "#084594",
                        }}
                      >
                        {Object.keys(subject)[0]}
                      </div>
                      <span>
                        Total marks:{" "}
                        <b
                          style={{ color: "#3182bd", fontFamily: "Lato-Bold" }}
                        >
                          {subject[Object.keys(subject)[0]]["marks_total"]}
                        </b>
                      </span>
                      <br></br>
                      <span>
                        Date:{" "}
                        <b
                          style={{ color: "#3182bd", fontFamily: "Lato-Bold" }}
                        >
                          {moment(
                            subject[Object.keys(subject)[0]]["date"],
                            "DD-MM-YYYY"
                          ).format("Do MMMM, YYYY")}
                        </b>
                      </span>
                      <br></br>
                      <span>
                        Time:{" "}
                        <b
                          style={{ color: "#084594", fontFamily: "Lato-Bold" }}
                        >
                          {subject[Object.keys(subject)[0]]["time"]}
                        </b>
                      </span>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </div>
          );
        }
      } else if (examsPage == "PREVIOUS") {
        var examCount = 0;
        if (selectedPage == "EXAMS") {
          return (
            <div style={{ marginTop: "-30px" }}>
              <Grid container spacing={1.5}>
                {exams.map((exam, index) => (
                  <Fragment>
                    {moment(exam["end_date"], "DD-MM-YYYY").isBefore(
                      moment()
                    ) ? (
                      <Grid item md={4}>
                        <span style={{ display: "none" }}>
                          {(examCount += 1)}
                        </span>
                        <Card
                          style={{
                            minHeight: "100%",
                            backgroundColor: "white",
                            boxShadow: "none",
                          }}
                          onClick={(e) => {
                            setSelectedExam(index);
                            setSelectedPage("EXAM");
                          }}
                        >
                          <CardActionArea
                            sx={[
                              {
                                borderRadius: "6px",
                                border: "1.5px solid #d7ecff",
                                transition:
                                  "background-color 0.5s, border-color 0.5s",
                              },
                              (theme) => ({
                                "&:hover": {
                                  backgroundColor: "#ebf5ff",
                                  borderColor: "#3182bd",
                                },
                                ".MuiCardActionArea-focusHighlight": {
                                  background: "transparent",
                                },

                                "&& .MuiTouchRipple-child": {
                                  backgroundColor: "#084594",
                                },
                              }),
                            ]}
                            style={{ padding: "8px 7px 12px 13px" }}
                          >
                            <h2
                              style={{
                                marginTop: "0px",
                                color: "#084594",

                                fontFamily: "Lato-Bold",
                              }}
                            >
                              {exam["test"]}
                            </h2>

                            <p
                              style={{
                                margin: "-18px 0px 15px 1px",
                                fontSize: "14px",
                                fontFamily: "Lato-Regular",
                                color: "#3182bd",
                              }}
                            >
                              {exam["semester"]}
                            </p>
                            <div
                              style={{
                                display: "flex",
                                width: "100%",
                                justifyContent: "space-between",
                              }}
                            >
                              <div
                                style={{
                                  width: "100%",
                                }}
                              >
                                <span
                                  style={{
                                    width: "20%",
                                    fontFamily: "Lato-Bold",
                                    color: "#084594",
                                  }}
                                >
                                  Start:{" "}
                                </span>
                                <span
                                  style={{
                                    margin: "0px 0px 0px 5px",
                                    fontSize: "14px",
                                    fontFamily: "Lato-Regular",
                                    width: "80%",
                                    color: "#3182bd",
                                  }}
                                >
                                  {moment(
                                    exam["start_date"],
                                    "DD-MM-YYYY"
                                  ).format("Do MMMM, YYYY")}
                                </span>
                                <div style={{ display: "flex", width: "100%" }}>
                                  <span
                                    style={{
                                      width: "15%",
                                      color: "#084594",

                                      fontFamily: "Lato-Bold",
                                    }}
                                  >
                                    End:{" "}
                                  </span>
                                  <span
                                    style={{
                                      margin: "0px 0px 0px 5px",
                                      fontSize: "14px",
                                      fontFamily: "Lato-Regular",
                                      width: "80%",
                                      color: "#3182bd",
                                    }}
                                  >
                                    {moment(
                                      exam["end_date"],
                                      "DD-MM-YYYY"
                                    ).format("Do MMMM, YYYY")}
                                  </span>
                                </div>
                              </div>

                              <ButtonBase
                                p={2}
                                value={index}
                                fullWidth
                                onClick={(e) => {
                                  setOpen2(true);
                                  setSelectedExam(index);
                                }}
                              >
                                <DeleteIcon
                                  style={{
                                    color: "#ff3333",
                                  }}
                                />
                              </ButtonBase>
                            </div>
                          </CardActionArea>
                        </Card>
                      </Grid>
                    ) : (
                      <Fragment></Fragment>
                    )}
                  </Fragment>
                ))}
                {examCount == 0 ? (
                  <div
                    style={{
                      marginTop: "200px",
                      height: "100px",
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <div style={{ textAlign: "center" }}>
                      <Fab
                        size="large"
                        style={{
                          border: "3px solid #aaa",
                          boxShadow: "none",
                          backgroundColor: "transparent",
                        }}
                        aria-label="add"
                      >
                        <PriorityHighIcon
                          style={{
                            color: "grey",
                          }}
                        />
                      </Fab>
                      <br />
                      <h4 style={{ color: "grey" }}>No previous Exams.</h4>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </Grid>
            </div>
          );
        } else if (selectedPage == "EXAM") {
          return (
            <div style={{ marginTop: "-15px" }}>
              <div style={{ display: "flex", marginBottom: "15px" }}>
                <Button
                  fullWidth
                  style={{
                    backgroundColor: "#3182bd",
                    color: "white",
                    width: "5%",
                    height: "35px",
                    borderTopLeftRadius: "6px",
                    borderBottomLeftRadius: "6px",
                    borderTopRightRadius: "0px",
                    borderBottomRightRadius: "0px",
                  }}
                  onClick={() => setSelectedPage("EXAMS")}
                >
                  <ArrowBackIcon style={{ fontSize: "25px" }} />
                </Button>
                <div
                  textAlign="center"
                  style={{
                    color: "#084594",
                    backgroundColor: "#d7ecff",
                    width: "95%",
                    height: "35px",
                    padding: "3px 0px 0px 25px",
                    alignItems: "center",
                    borderTopLeftRadius: "0px",
                    borderBottomLeftRadius: "0px",
                    borderTopRightRadius: "6px",
                    borderBottomRightRadius: "6px",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      marginLeft: "-10px",
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <h2 style={{ fontSize: "20px" }}>
                      {exams[selectedExam]["test"] + " - "}
                      {exams[selectedExam]["semester"]}
                    </h2>
                    <div
                      style={{
                        fontSize: "20px",
                        fontWeight: "bold",
                        fontFamily: "Bahnschrift",
                        fontWeight: "lighter",
                        marginTop: "17px",
                      }}
                    >
                      {moment(
                        exams[selectedExam]["start_date"],
                        "DD-MM-YYYY"
                      ).format("Do MMMM, YYYY") + " "}
                      to
                      {" " +
                        moment(
                          exams[selectedExam]["end_date"],
                          "DD-MM-YYYY"
                        ).format("Do MMMM, YYYY")}
                    </div>
                  </div>
                </div>
              </div>
              <Grid container spacing={1.5}>
                {exams[selectedExam]["subjects"].map((subject, index) => (
                  <Grid item xs={4}>
                    <Card
                      style={{
                        minHeight: "100%",
                        backgroundColor: "white",
                        boxShadow: "none",
                        padding: "8px 12px 10px 12px",
                        border: "1.5px solid #d7ecff",
                        borderRadius: "6px",
                      }}
                    >
                      <div
                        style={{
                          fontSize: "20px",
                          fontWeight: "bold",
                          fontFamily: "Lato-Bold",
                          marginBottom: "0px",
                          color: "#084594",
                        }}
                      >
                        {Object.keys(subject)[0]}
                      </div>
                      <span>
                        Total marks:{" "}
                        <b
                          style={{ color: "#3182bd", fontFamily: "Lato-Bold" }}
                        >
                          {subject[Object.keys(subject)[0]]["marks_total"]}
                        </b>
                      </span>
                      <br></br>
                      <span>
                        Date:{" "}
                        <b
                          style={{ color: "#3182bd", fontFamily: "Lato-Bold" }}
                        >
                          {moment(
                            subject[Object.keys(subject)[0]]["date"],
                            "DD-MM-YYYY"
                          ).format("Do MMMM, YYYY")}
                        </b>
                      </span>
                      <br></br>
                      <span>
                        Time:{" "}
                        <b
                          style={{ color: "#084594", fontFamily: "Lato-Bold" }}
                        >
                          {subject[Object.keys(subject)[0]]["time"]}
                        </b>
                      </span>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </div>
          );
        }
      } else if (examsPage == "ADDEXAM") {
        return (
          <div style={{ marginTop: "-15px" }}>
            <TextField
              size="small"
              placeholder="Exam name"
              onChange={(e) => setExamName(e.target.value)}
              value={examName}
              fullWidth
              style={{ marginTop: "2px", marginBottom: "15px" }}
            />

            {newExam.map((subject, index) => (
              <div
                style={{
                  backgroundColor: "d7ecff",
                  padding: "15px",
                  borderRadius: "6px",
                  border: "2px solid #d7ecff",
                  marginBottom: "25px",
                }}
              >
                <h2 style={{ textAlign: "center", marginTop: "-5px" }}>
                  {Object.keys(subject)[0]
                    ? Object.keys(subject)[0]
                    : "Subject " + (index + 1)}
                </h2>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <span
                      style={{ fontSize: "14px", fontFamily: "Lato-Regular" }}
                    >
                      Subject Name
                    </span>
                    <TextField
                      key={"subject" + index}
                      placeholder="Subject Name"
                      onChange={(e) =>
                        onChangeNewSubjectText(e.target.value, index)
                      }
                      value={Object.keys(subject)[0]}
                      size="small"
                      fullWidth
                      style={{ marginTop: "2px", backgroundColor: "white" }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <span
                      style={{ fontSize: "14px", fontFamily: "Lato-Regular" }}
                    >
                      Total Marks
                    </span>
                    <TextField
                      key={"marks" + index}
                      keyboardType="numeric"
                      placeholder="Marks"
                      onChange={(e) =>
                        onChangeNewSubjectMarks(e.target.value, index)
                      }
                      value={subject[Object.keys(subject)[0]]["marks_total"]}
                      size="small"
                      fullWidth
                      type="number"
                      style={{ marginTop: "2px", backgroundColor: "white" }}
                    />
                  </Grid>
                </Grid>
                <div
                  style={{
                    marginTop: "20px",
                    textAlign: "center",
                    marginBottom: "5px",
                    fontFamily: "Bahnschrift",
                    fontSize: "20px",
                  }}
                >
                  {Object.prototype.toString.call(
                    subject[Object.keys(subject)[0]]["date"]
                  ) === "[object Date]"
                    ? subject[Object.keys(subject)[0]]["date"].getDate() +
                      " " +
                      monthNames[
                        subject[Object.keys(subject)[0]]["date"].getMonth()
                      ] +
                      "  " +
                      getTimeInWords(
                        subject[Object.keys(subject)[0]]["time_start"]
                      ) +
                      " to " +
                      getTimeInWords(
                        subject[Object.keys(subject)[0]]["time_end"]
                      )
                    : moment(
                        subject[Object.keys(subject)[0]]["date"],
                        "DD-MM-YYYY"
                      ).format("Do MMMM, YYYY")}
                </div>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>

                    <DatePicker
                      inputFormat="dd-MM-yyyy"
                      value={subject[Object.keys(subject)[0]]["date"]}
                      sx={{ height:"40px",  width:"100%", marginBottom:"20px"}}
                      onChange={(date) =>
                        onChangeSubjectDate(
                          date,
                          index,
                          subject[Object.keys(subject)[0]]["id"]
                        )
                      }                      fullWidth

                      renderInput={(params) => (
                        <TextField
                          style={{
                            width:"100%",
                            marginTop: "2px",
                            marginBottom: "5px",
                            height:"40px",
                          }}
                          size="small"
                          fullWidth
                          {...params}
                        />
                      )}
                    />



                    </LocalizationProvider>
                    <div
                      style={{
                        width: "100%",
                        fontFamily: "Bahnschrift",
                        textAlign: "center",
                        marginTop: "5px",
                      }}
                    >
                      EXAM DATE
                    </div>
                  </Grid>
                  <Grid item xs={4}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <MobileTimePicker
                        value={subject[Object.keys(subject)[0]]["time_start"]}
                        sx={{
                          height: "40px",
                          width: "100%",
                          marginBottom: "20px",
                        }}
                        onChange={(time) =>
                          onChangeSubjectTimeStart(
                            time,
                            index,
                            subject[Object.keys(subject)[0]]["id"]
                          )
                        }
                        renderInput={(params) => (
                          <TextField
                            style={{
                              backgroundColor: "white",
                              marginTop: "2px",
                              marginBottom: "5px",
                            }}
                            fullWidth
                            size="small"
                            {...params}
                          />
                        )}
                      />
                    </LocalizationProvider>
                    <div
                      style={{
                        width: "100%",
                        fontFamily: "Bahnschrift",
                        textAlign: "center",
                        marginTop: "5px",
                      }}
                    >
                      START TIME
                    </div>
                  </Grid>
                  <Grid item xs={4}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <MobileTimePicker
                        value={subject[Object.keys(subject)[0]]["time_end"]}
                        sx={{
                          height: "40px",
                          width: "100%",
                          marginBottom: "20px",
                        }}
                        onChange={(time) =>
                          onChangeSubjectTimeEnd(
                            time,
                            index,
                            subject[Object.keys(subject)[0]]["id"]
                          )
                        }
                        renderInput={(params) => (
                          <TextField
                            style={{
                              backgroundColor: "white",
                              marginTop: "2px",
                              marginBottom: "5px",
                            }}
                            size="small"
                            fullWidth
                            {...params}
                          />
                        )}
                      />
                    </LocalizationProvider>
                    <div
                      style={{
                        width: "100%",
                        fontFamily: "Bahnschrift",
                        textAlign: "center",
                        marginTop: "5px",
                      }}
                    >
                      END TIME
                    </div>
                  </Grid>
                </Grid>
                <br></br>
                <Grid container spacing={2}>
                  <Grid item xs={3}></Grid>
                  <Grid item xs={3}>
                    <Button
                      style={{
                        backgroundColor: "#3283c9",
                        borderRadius: 5,
                        justifyContent: "flex-start",
                        height: "35px",
                      }}
                      fullWidth
                      onClick={() => removeNewExamDict(index)}
                    >
                      <DeleteIcon
                        name="delete"
                        size={30}
                        style={{ color: "white" }}
                      />

                      <span
                        style={{
                          textAlign: "center",
                          color: "white",
                          marginTop: "1px",
                          marginLeft: "13px",
                          fontFamily: "Bahnschrift",

                          fontSize: "16px",
                        }}
                      >
                        DELETE SUBJECT
                      </span>
                    </Button>
                  </Grid>
                  <Grid item xs={3}>
                    <Button
                      style={{
                        backgroundColor: "#3283c9",
                        borderRadius: 5,
                        justifyContent: "flex-start",
                        height: "35px",
                      }}
                      fullWidth
                      onClick={() => addNewExamDict()}
                    >
                      <AddIcon
                        name="add"
                        style={{ color: "white", fontSize: "30px" }}
                      />
                      <span
                        style={{
                          textAlign: "center",
                          color: "white",
                          marginTop: "1px",
                          marginLeft: "18px",
                          fontFamily: "Bahnschrift",

                          fontSize: "16px",
                        }}
                      >
                        ADD SUBJECT
                      </span>
                    </Button>
                  </Grid>
                  <Grid item xs={3}></Grid>
                </Grid>
              </div>
            ))}
            <br></br>
          </div>
        );
      }
    }
  }

  if (!state.auth.isLoading) {
    if (state.auth.isAuthenticated) {
      if (state.auth.user.is_manager) {
        if (isLoading || !isReady) {
          return (
            <Fragment>
              <Sidebar />
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  backgroundColor: "white",
                  marginTop: "46vh",
                }}
              >
                <CircularProgress />
              </div>
            </Fragment>
          );
        } else {
          return (
            <Fragment>
              <Sidebar
                tabs={
                  <Grid container spacing={1.5}>
                    <Grid item md={4}>
                      {examsPage == "UPCOMING" ? (
                        <Button
                          fullWidth
                          value={"UPCOMING"}
                          p={2}
                          style={{
                            backgroundColor: "#3182bd",
                            color: "white",
                            height: "30px",
                          }}
                        >
                          <h3
                            style={{
                              marginTop: "17px",
                              fontSize: "15px",
                            }}
                          >
                            UPCOMING EXAMS
                          </h3>
                        </Button>
                      ) : (
                        <Button
                          fullWidth
                          value={"UPCOMING"}
                          onClick={() => {
                            setExamsPage("UPCOMING");
                            setSelectedPage("EXAMS");
                          }}
                          p={2}
                          style={{
                            backgroundColor: "#d7ecff",
                            color: "white",
                            height: "30px",
                          }}
                        >
                          <h3
                            style={{
                              marginTop: "17px",
                              fontSize: "15px",
                              color: "#084594",
                            }}
                          >
                            UPCOMING EXAMS
                          </h3>
                        </Button>
                      )}
                    </Grid>
                    <Grid item md={4}>
                      {examsPage == "PREVIOUS" ? (
                        <Button
                          fullWidth
                          value="PREVIOUS"
                          p={2}
                          style={{
                            backgroundColor: "#3182bd",
                            color: "white",
                            height: "30px",
                          }}
                        >
                          <h3
                            style={{
                              marginTop: "17px",
                              fontSize: "15px",
                            }}
                          >
                            PREVIOUS EXAMS
                          </h3>
                        </Button>
                      ) : (
                        <Button
                          fullWidth
                          value="PREVIOUS"
                          onClick={() => {
                            setExamsPage("PREVIOUS");
                            setSelectedPage("EXAMS");
                          }}
                          p={2}
                          style={{
                            backgroundColor: "#d7ecff",
                            color: "white",
                            height: "30px",
                          }}
                        >
                          <h3
                            style={{
                              marginTop: "17px",
                              fontSize: "15px",
                              color: "#084594",
                            }}
                          >
                            PREVIOUS EXAMS
                          </h3>
                        </Button>
                      )}
                    </Grid>
                    <Grid item md={4}>
                      {examsPage == "ADDEXAM" ? (
                        <Button
                          fullWidth
                          value="ADDEXAM"
                          p={2}
                          style={{
                            backgroundColor: "#3182bd",
                            color: "white",
                            height: "30px",
                          }}
                        >
                          <h3
                            style={{
                              marginTop: "17px",
                              fontSize: "15px",
                            }}
                          >
                            ADD NEW EXAMS
                          </h3>
                        </Button>
                      ) : (
                        <Button
                          fullWidth
                          value="ADDEXAM"
                          onClick={() => {
                            setExamsPage("ADDEXAM");
                          }}
                          p={2}
                          style={{
                            backgroundColor: "#d7ecff",
                            color: "white",
                            height: "30px",
                          }}
                        >
                          <h3
                            style={{
                              marginTop: "17px",
                              fontSize: "15px",
                              color: "#084594",
                            }}
                          >
                            ADD NEW EXAMS
                          </h3>
                        </Button>
                      )}
                    </Grid>
                  </Grid>
                }
              />
              <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={() => setOpen(false)}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                  timeout: 500,
                }}
              >
                <Fade in={open}>
                  <div
                    style={{
                      position: "absolute",
                      top: "50%",
                      backgroundColor: "white",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      width: 400,
                      padding: "20px",
                      borderRadius: "6px",
                    }}
                  >
                    <div style={{ marginTop: "-25px" }}>
                      <div
                        style={{
                          marginTop: "20px",
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        <IconButton size="small">
                          <CloseIcon
                            onClick={() => setOpen(false)}
                            style={{ color: "#3182bd" }}
                          />
                        </IconButton>
                      </div>
                      <h3>Create exam?</h3>
                      <p>
                        A new exam will be scheduled and notifications will be
                        sent to all students and parents of this class.{" "}
                        <br></br>
                        <br></br>This action cannot be reversed.
                      </p>
                      <br></br>
                      <div
                        style={{
                          display: "flex",
                          width: "100%",
                          justifyContent: "flex-end",
                        }}
                      >
                        <Button
                          onClick={() => addExam()}
                          style={{ height: "30px" }}
                          variant="contained"
                        >
                          Yes
                        </Button>
                        <Button
                          style={{ height: "30px", marginLeft: "10px" }}
                          variant="contained"
                          onClick={() => setOpen(false)}
                        >
                          No
                        </Button>
                      </div>
                    </div>
                  </div>
                </Fade>
              </Modal>
              <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open2}
                onClose={() => setOpen2(false)}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                  timeout: 500,
                }}
              >
                <Fade in={open2}>
                  <div
                    style={{
                      position: "absolute",
                      top: "50%",
                      backgroundColor: "white",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      width: 400,
                      padding: "20px",
                      borderRadius: "6px",
                    }}
                  >
                    <div style={{ marginTop: "-25px" }}>
                      <div
                        style={{
                          marginTop: "20px",
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        <IconButton size="small">
                          <CloseIcon
                            onClick={() => setOpen2(false)}
                            style={{ color: "#3182bd" }}
                          />
                        </IconButton>
                      </div>
                      <h3>Delete exam?</h3>
                      <p>
                        This exam will be deleted.
                        <br></br>
                        <br></br>This action cannot be reversed.
                      </p>
                      <br></br>
                      <div
                        style={{
                          display: "flex",
                          width: "100%",
                          justifyContent: "flex-end",
                        }}
                      >
                        <Button
                          onClick={() => deleteExam()}
                          style={{ height: "30px" }}
                          variant="contained"
                        >
                          Yes
                        </Button>
                        <Button
                          style={{ height: "30px", marginLeft: "10px" }}
                          variant="contained"
                          onClick={() => setOpen2(false)}
                        >
                          No
                        </Button>
                      </div>
                    </div>
                  </div>
                </Fade>
              </Modal>
              <div
                style={{
                  margin: "82px 15px 20px 85px",
                  backgroundColor: "white",
                }}
              >
                <Grid container spacing={1.5}>
                  <Grid item md={9}>
                    {getContent()}
                  </Grid>
                  <Grid item xs={3} style={{ marginTop: "-20px" }}>
                    <span
                      style={{
                        color: "#3182bd",
                        fontFamily: "Lato-Regular",
                        fontSize: "14px",
                      }}
                    >
                      Semester
                    </span>
                    <Select
                      value={semester}
                      onChange={(e) => handleSemesterChange(e)}
                      style={{
                        marginTop: "2px",
                        height: "40px",
                        marginBottom: "5px",
                      }}
                      fullWidth
                      variant={"outlined"}
                    >
                      {getSemesterList()}
                    </Select>
                    <span
                      style={{
                        color: "#3182bd",
                        fontFamily: "Lato-Regular",
                        fontSize: "14px",
                      }}
                    >
                      Class
                    </span>
                    <Select
                      value={classs}
                      onChange={(e) => handleClassChange(e)}
                      style={{
                        marginTop: "2px",
                        height: "40px",
                        marginBottom: "5px",
                      }}
                      fullWidth
                      variant={"outlined"}
                    >
                      {getClassList()}
                    </Select>
                    <span
                      style={{
                        color: "#3182bd",
                        fontFamily: "Lato-Regular",
                        fontSize: "14px",
                      }}
                    >
                      Division
                    </span>
                    <Select
                      value={division}
                      onChange={(e) => handleDivisionChange(e)}
                      style={{
                        marginTop: "2px",
                        height: "40px",
                        marginBottom: "5px",
                      }}
                      fullWidth
                      variant={"outlined"}
                    >
                      {getDivisionList()}
                    </Select>
                    {examsPage == "ADDEXAM" ? (
                      <Button
                        style={{
                          height: "35px",
                          marginTop: "10px",
                          backgroundColor: "#3283c9",
                        }}
                        fullWidth
                        onClick={() => {
                          setOpen(true);
                        }}
                      >
                        <span
                          style={{
                            color: "white",
                            fontFamily: "Bahnschrift",
                            fontSize: "18px",
                            marginTop: "5px",
                          }}
                        >
                          Schedule exam
                        </span>
                      </Button>
                    ) : (
                      ""
                    )}
                    {isSubmitting ? (
                      <div
                        style={{
                          marginTop: "60px",
                          backgroundColor: "white",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <CircularProgress />
                        <h3>Submitting...</h3>
                      </div>
                    ) : (
                      ""
                    )}
                  </Grid>
                </Grid>
              </div>
            </Fragment>
          );
        }
      } else {
        createAlert("ERROR", "Permission Denied.", "Please Login as Principal");
        dispatch({
          type: "AUTH_ERROR",
        });
        return <Navigate to="/login" />;
      }
    } else {
      return <Navigate to="/login" />;
    }
  } else {
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          backgroundColor: "white",
          marginTop: "46vh",
        }}
      >
        <CircularProgress />
      </div>
    );
  }
}

export default Exams;
