import { useState, Fragment, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Sidebar from "../components/Sidebar";
import Box from "@mui/material/Box";
import { API_URL, alertMessage } from "../actions/auth";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import { useParams } from "react-router-dom";
import TextField from "@mui/material/TextField";
import Fab from "@mui/material/Fab";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import { DatePicker } from '@mui/x-date-pickers';
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import moment from "moment";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";

function makeid(length) {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

const colorList = ["#e5f5e0", "#d7ecff", "#fee6ce"];
const colorList2 = ["#31a354", "#3283c9", "#e6550d"];
const colorList3 = ["#addd8e", "#9ecae1", "#a1d99b"];

function Approvals() {
  const dispatch = useDispatch();
  function createAlert(type, title, message) {
    dispatch(alertMessage(type, title, message));
  }
  const navigate = useNavigate();
  const params = new URLSearchParams(window.location.search);
  const state = useSelector((state) => state);
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  const handlePageSelect = (event) => {
    setSeeLeaves(false);
    setSelectedPage(event.currentTarget.value);
  };
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);

  const [points, setPoints] = useState(0);
  const [achievement, setAchievement] = useState(0);

  const [selectedPage, setSelectedPage] = useState(
    params.get("tab") ? params.get("tab") : "TEACHERLEAVES"
  );

  const colorList = ["#a1d99b", "#9ecae1", "#fdae6b"];

  const [isLoading, setIsLoading] = useState(true);
  const [selectedApprove, setSelectedApprove] = useState(false);

  const [leaveId, setLeaveId] = useState("");
  const [email, setEmail] = useState("");
  const [seeLeaves, setSeeLeaves] = useState("");
  const [leaveDate, setLeaveDate] = useState("");
  const [profilesApproved, setProfilesApproved] = useState([]);
  const [profilesRejected, setProfilesRejected] = useState([]);
  const [profilesPending, setProfilesPending] = useState([]);
  const [profiles, setProfiles] = useState([]);
  const [profile, setProfile] = useState({});

  const [rankingList, setRankingList] = useState([]);

  const [teacherLeaveRequests, setTeacherLeaveRequests] = useState([]);

  function getData() {
    var body = {};
    const config = {
      headers: {
        Authorization: state.auth.token,
        "X-CSRFToken": state.auth.csrfToken,
        "Content-Type": "application/json",
      },
    };
    body["institution_id"] = state.auth.user.institution_id;
    body = JSON.stringify(body);

    axios
      .post(API_URL + "/get_leave_requests_principal", body, config)
      .then((res) => {
        setTeacherLeaveRequests(res.data["teacher_requests"]);
        axios
          .post(API_URL + "/get_account_approvals_list_teachers", body, config)
          .then((res) => {
            var a = [];
            var r = [];
            var p = [];

            for (var i = 0; i < res.data.length; i++) {
              if (res.data[i]["initiated"] == "PENDING") {
                p.push(res.data[i]);
              } else if (res.data[i]["initiated"] == "APPROVED") {
                a.push(res.data[i]);
              } else {
                r.push(res.data[i]);
              }
            }
            setProfiles(res.data);
            setProfilesApproved(a);
            setProfilesRejected(r);
            setProfilesPending(p);

            axios
              .post(API_URL + "/get_ranking_approval_list", body, config)
              .then((res) => {
                setRankingList(res.data);
                setIsLoading(false);
              });
          });
      });
  }

  useEffect(() => {
    if (state.auth.isLoading) {
      dispatch({ type: "ENTRY_PAGE", payload: window.location.pathname });
      navigate("/", { replace: true });
    } else {
      getData();
    }
  }, []);

  if (state.auth.refresh == "REFRESH_DATA") {
    getData();
    dispatch({ type: "REFRESH_DATA", payload: null });
  }

  function approveLeave(approval) {
    var body = {};
    const config = {
      headers: {
        Authorization: state.auth.token,
        "X-CSRFToken": state.auth.csrfToken,
        "Content-Type": "application/json",
      },
    };
    body["leave_request_id"] = leaveId;
    body["leave_date"] = leaveDate;
    body["email"] = email;
    body["approval"] = approval ? "APPROVED" : "REJECTED";
    body["date"] = moment().format("DD-MM-YYYY");
    body = JSON.stringify(body);

    axios
      .post(API_URL + "/approve_leave_requests", body, config)
      .then((res) => {
        getData();
        handleClose();
        createAlert(
          "SUCCESS",
          "Success.",
          approval ? "Leave APPROVED" : "Leave REJECTED"
        );
      })
      .catch((err) => {
        createAlert("ERROR", "Error", "An error occured.");
      });
  }

  function approveProfile(approval) {
    var body = {};
    const config = {
      headers: {
        Authorization: state.auth.token,
        "X-CSRFToken": state.auth.csrfToken,
        "Content-Type": "application/json",
      },
    };
    if (approval == "DELETE") {
      body["email"] = email;
      body = JSON.stringify(body);

      axios
        .post(API_URL + "/delete_account", body, config)
        .then((res) => {
          getData();
          setOpen1(false);
          setOpen3(false);

          createAlert("SUCCESS", "Success.", "Account DELETED");
        })
        .catch((err) => {
          createAlert("ERROR", "Error", "An error occured.");
        });
    } else {
      body["email"] = email;
      body["approval"] = approval;
      body = JSON.stringify(body);

      axios
        .post(API_URL + "/approve_account", body, config)
        .then((res) => {
          getData();
          setOpen1(false);
          setOpen3(false);

          createAlert(
            "SUCCESS",
            "Success.",
            approval ? "Account APPROVED" : "Account REJECTED"
          );
        })
        .catch((err) => {
          createAlert("ERROR", "Error", "An error occured.");
        });
    }
  }

  function approveAchievement(approval) {
    var body = {};
    const config = {
      headers: {
        Authorization: state.auth.token,
        "X-CSRFToken": state.auth.csrfToken,
        "Content-Type": "application/json",
      },
    };

    body["email"] = email;
    body["id"] = achievement;
    body["approval"] = approval;
    body["points"] = points;
    body = JSON.stringify(body);

    axios
      .post(API_URL + "/approve_achievement", body, config)
      .then((res) => {
        getData();
        setOpen2(false);
        createAlert(
          "SUCCESS",
          "Success.",
          approval ? "Achievement APPROVED" : "Achievement REJECTED"
        );
      })
      .catch((err) => {
        createAlert("ERROR", "Error", "An error occured.");
      });
  }

  function getPageContent() {
    if (selectedPage == "TEACHERLEAVES") {
      if (seeLeaves) {
        return (
          <Fragment>
            <Grid container spacing={1.5}>
              <Grid item md={8}>
                {profile["leaves"].length == 0 ? (
                  <div
                    style={{
                      marginTop: "200px",
                      height: "100px",
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <div style={{ textAlign: "center" }}>
                      <Fab
                        size="large"
                        style={{
                          border: "3px solid #aaa",
                          boxShadow: "none",
                          backgroundColor: "transparent",
                        }}
                        aria-label="add"
                      >
                        <PriorityHighIcon
                          style={{
                            color: "grey",
                          }}
                        />
                      </Fab>
                      <br />
                      <h4 style={{ color: "grey" }}>
                        No teacher leave requests.
                      </h4>
                    </div>
                  </div>
                ) : (
                  ""
                )}
                {profile["leaves"].map((leave, index) => (
                  <Box
                    p={2}
                    style={{
                      height: "80px",
                      marginBottom: "15px",
                      borderRadius: "15px",
                      border:
                        leave["status"] == "PENDING"
                          ? "1.5px solid" + "#3182bd"
                          : "none",
                      backgroundColor:
                        leave["status"] == "PENDING"
                          ? "white"
                          : leave["status"] == "APPROVED"
                          ? colorList[0]
                          : colorList[2],
                      color: "black",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItemsL: "center",
                      }}
                    >
                      <div style={{ width: "90%" }}>
                        <div
                          style={{
                            margin: "-25px 0px 0px -3px",
                          }}
                        >
                          <h3
                            style={{
                              fontFamily: "Lato-Bold",
                              lineHeight: "20px",
                              color:
                                leave["status"] == "PENDING"
                                  ? colorList2[1]
                                  : leave["status"] == "APPROVED"
                                  ? colorList2[0]
                                  : colorList2[2],
                            }}
                          >
                            {leave["subject"]}
                          </h3>
                        </div>
                        <div
                          style={{
                            margin: "-15px 0px 0px -3px",
                            fontSize: "14px",
                          }}
                        >
                          {moment(leave["date"], "DD-MM-YYYY").format(
                            "Do MMMM, YYYY"
                          )}
                        </div>
                      </div>

                      <div
                        style={{
                          width: "100px",
                          display: "flex",
                          justifyContent: "center",
                          marginTop:
                            leave["status"] == "PENDING" ? "-12px" : "-5px",
                          marginRight: "-15px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          <Box
                            textAlign="center"
                            p={1}
                            style={{
                              height: "35px",
                              width: "35px",
                              borderRadius: "60px",
                              backgroundColor:
                                leave["status"] == "PENDING"
                                  ? colorList2[1]
                                  : leave["status"] == "APPROVED"
                                  ? colorList2[0]
                                  : colorList2[2],
                              color: "white",
                            }}
                          >
                            {leave["status"] == "PENDING" ? (
                              <PriorityHighIcon
                                style={{
                                  fontSize: "30px",
                                  margin: "-5px 0px 0px -5.5px",
                                }}
                              />
                            ) : leave["status"] == "APPROVED" ? (
                              <CheckIcon
                                style={{
                                  fontSize: "30px",
                                  margin: "-5px 0px 0px -5.5px",
                                }}
                              />
                            ) : (
                              <CloseIcon
                                style={{
                                  fontSize: "30px",
                                  margin: "-5px 0px 0px -5.5px",
                                }}
                              />
                            )}
                          </Box>
                          <b
                            style={{
                              marginTop: "5px",
                              color:
                                leave["status"] == "PENDING"
                                  ? colorList2[1]
                                  : leave["status"] == "APPROVED"
                                  ? colorList2[0]
                                  : colorList2[2],
                            }}
                          >
                            <span
                              style={{
                                fontFamily: "Bahnschrift",
                                fontWeight: "lighter",
                              }}
                            >
                              {leave["status"]}
                            </span>
                          </b>
                        </div>
                      </div>
                    </div>
                  </Box>
                ))}
              </Grid>
            </Grid>
          </Fragment>
        );
      } else {
        return (
          <Fragment>
            <Grid container spacing={1.5}>
              {teacherLeaveRequests.length == 0 ? (
                <div
                  style={{
                    marginTop: "200px",
                    height: "100px",
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <div style={{ textAlign: "center" }}>
                    <Fab
                      size="large"
                      style={{
                        border: "3px solid #aaa",
                        boxShadow: "none",
                        backgroundColor: "transparent",
                      }}
                      aria-label="add"
                    >
                      <PriorityHighIcon
                        style={{
                          color: "grey",
                        }}
                      />
                    </Fab>
                    <br />
                    <h4 style={{ color: "grey" }}>
                      No teacher leave requests.
                    </h4>
                  </div>
                </div>
              ) : (
                ""
              )}
              {teacherLeaveRequests.map((leave, index) => (
                <Grid item md={3}>
                  <Box
                    style={{
                      minHeight: "100%",
                      borderRadius: "6px",
                      border: "1.3px solid #d7ecff",
                      backgroundColor: "white",
                      boxShadow: "none",
                      padding: "15px",
                    }}
                  >
                    <div
                      style={{
                        margin: "-5px 0px 0px 0px",
                        fontSize: "18px",
                        color: "#084594",
                        fontFamily: "Lato-Bold",
                      }}
                    >
                      {leave["name"]}
                    </div>
                    <div style={{ fontSize: "14px" }}>{leave["subject"]}</div>
                    <div
                      style={{
                        marginTop: "10px",
                        color: "#3182bd",
                        fontFamily: "Lato-Bold",
                      }}
                    >
                      {leave["class"]}
                    </div>
                    <div style={{ marginTop: "-5px" }}>
                      {" "}
                      {moment(leave["date"], "DD-MM-YYYY").format(
                        "Do MMMM, YYYY"
                      )}
                    </div>

                    <div style={{ margin: "0px 0px 0px 0px" }}>
                      {leave["description"]}
                    </div>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        marginBottom: "-5px",
                        marginRight: "-5px",
                      }}
                    >
                      <Fab
                        size="small"
                        style={{
                          transform: "scale(0.8)",
                          boxShadow: "none",
                          backgroundColor: "#3182bd",
                        }}
                        onClick={() => {
                          setLeaveId(leave["id"]);
                          setEmail(leave["email"]);
                          setLeaveDate(leave["date"]);
                          setOpen(true);
                        }}
                        aria-label="add"
                      >
                        <PriorityHighIcon
                          style={{
                            color: "white",
                          }}
                        />
                      </Fab>
                    </div>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Fragment>
        );
      }
    } else if (selectedPage == "PROFILES") {
      return (
        <Fragment>
          {profiles.length == 0 ? (
            <Grid container spacing={1.5} style={{ marginTop: "-20px" }}>
              <div
                style={{
                  marginTop: "200px",
                  height: "100px",
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <div style={{ textAlign: "center" }}>
                  <Fab
                    size="large"
                    style={{
                      border: "3px solid #aaa",
                      boxShadow: "none",
                      backgroundColor: "transparent",
                    }}
                    aria-label="add"
                  >
                    <PriorityHighIcon
                      style={{
                        color: "grey",
                      }}
                    />
                  </Fab>
                  <br />
                  <h4 style={{ color: "grey" }}>
                    No Teacher profile approvals pending.
                  </h4>
                </div>
              </div>
            </Grid>
          ) : (
            <Fragment>
              {profilesPending.length != 0 ? <h3>Pending Profiles</h3> : ""}
              <Grid container spacing={1.5} style={{ marginTop: "-20px" }}>
                {profilesPending.map((profile, index) => (
                  <Grid item md={3}>
                    <Box
                      onClick={() => {
                        setEmail(profile["email"]);
                        setProfile(profile);
                        setOpen1(true);
                      }}
                      style={{
                        cursor: "pointer",
                        minHeight: "100%",
                        borderRadius: "6px",
                        border: "1.3px solid #d7ecff",
                        backgroundColor: "white",
                        boxShadow: "none",
                        padding: "15px",
                      }}
                    >
                      <div
                        style={{
                          margin: "-5px 0px 0px 0px",
                          fontSize: "18px",
                          color: "#084594",
                          fontFamily: "Lato-Bold",
                        }}
                      >
                        {profile["email"]}
                      </div>
                      <div style={{ fontSize: "14px" }}>
                        {profile["firstname"] +
                          " " +
                          profile["middlename"] +
                          " " +
                          profile["lastname"]}
                      </div>

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          marginBottom: "-5px",
                          marginRight: "-5px",
                        }}
                      >
                        <Fab
                          size="small"
                          style={{
                            transform: "scale(0.8)",
                            boxShadow: "none",
                            backgroundColor: "#3182bd",
                          }}
                          onClick={() => {
                            setEmail(profile["email"]);
                            setProfile(profile);

                            setOpen1(true);
                          }}
                          aria-label="add"
                        >
                          <PriorityHighIcon
                            style={{
                              color: "white",
                            }}
                          />
                        </Fab>
                      </div>
                    </Box>
                  </Grid>
                ))}
              </Grid>
              {profilesApproved.length != 0 ? <h3>Approved Profiles</h3> : ""}
              <Grid container spacing={1.5} style={{ marginTop: "-20px" }}>
                {profilesApproved.map((profile, index) => (
                  <Grid item md={3}>
                    <Box
                      onClick={() => {
                        setEmail(profile["email"]);
                        setProfile(profile);

                        setOpen1(true);
                      }}
                      style={{
                        cursor: "pointer",
                        minHeight: "100%",
                        borderRadius: "6px",
                        border: "1.3px solid #d7ecff",
                        backgroundColor: "white",
                        boxShadow: "none",
                        padding: "15px",
                      }}
                    >
                      <div
                        style={{
                          margin: "-5px 0px 0px 0px",
                          fontSize: "18px",
                          color: "#084594",
                          fontFamily: "Lato-Bold",
                        }}
                      >
                        {profile["email"]}
                      </div>
                      <div style={{ fontSize: "14px" }}>
                        {profile["firstname"] +
                          " " +
                          profile["middlename"] +
                          " " +
                          profile["lastname"]}
                      </div>

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          marginBottom: "-5px",
                          marginRight: "-5px",
                        }}
                      >
                        <Fab
                          size="small"
                          style={{
                            transform: "scale(0.8)",
                            boxShadow: "none",
                            backgroundColor: "#a1d99b",
                          }}
                          onClick={() => {
                            setEmail(profile["email"]);
                            setProfile(profile);

                            setOpen1(true);
                          }}
                          aria-label="add"
                        >
                          <CheckIcon
                            style={{
                              color: "white",
                            }}
                          />
                        </Fab>
                      </div>
                    </Box>
                  </Grid>
                ))}
              </Grid>
              {profilesRejected.length != 0 ? <h3>Rejected Profiles</h3> : ""}
              <Grid container spacing={1.5} style={{ marginTop: "-20px" }}>
                {profilesRejected.map((profile, index) => (
                  <Grid item md={3}>
                    <Box
                      onClick={() => {
                        setEmail(profile["email"]);
                        setProfile(profile);

                        setOpen1(true);
                      }}
                      style={{
                        cursor: "pointer",
                        minHeight: "100%",
                        borderRadius: "6px",
                        border: "1.3px solid #d7ecff",
                        backgroundColor: "white",
                        boxShadow: "none",
                        padding: "15px",
                      }}
                    >
                      <div
                        style={{
                          margin: "-5px 0px 0px 0px",
                          fontSize: "18px",
                          color: "#084594",
                          fontFamily: "Lato-Bold",
                        }}
                      >
                        {profile["email"]}
                      </div>
                      <div style={{ fontSize: "14px" }}>
                        {profile["firstname"] +
                          " " +
                          profile["middlename"] +
                          " " +
                          profile["lastname"]}
                      </div>

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          marginBottom: "-5px",
                          marginRight: "-5px",
                        }}
                      >
                        <Fab
                          size="small"
                          style={{
                            transform: "scale(0.8)",
                            boxShadow: "none",
                            backgroundColor: "#ff3333",
                          }}
                          onClick={() => {
                            setEmail(profile["email"]);
                            setProfile(profile);

                            setOpen1(true);
                          }}
                          aria-label="add"
                        >
                          <CloseIcon
                            style={{
                              color: "white",
                            }}
                          />
                        </Fab>
                      </div>
                    </Box>
                  </Grid>
                ))}
              </Grid>{" "}
            </Fragment>
          )}
        </Fragment>
      );
    } else if (selectedPage == "RANKING") {
      return (
        <Fragment>
          <Grid container spacing={1.5}>
            {rankingList.length == 0 ? (
              <div
                style={{
                  marginTop: "200px",
                  height: "100px",
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <div style={{ textAlign: "center" }}>
                  <Fab
                    size="large"
                    style={{
                      border: "3px solid #aaa",
                      boxShadow: "none",
                      backgroundColor: "transparent",
                    }}
                    aria-label="add"
                  >
                    <PriorityHighIcon
                      style={{
                        color: "grey",
                      }}
                    />
                  </Fab>
                  <br />
                  <h4 style={{ color: "grey" }}>
                    No Student ranking approvals pending.
                  </h4>
                </div>
              </div>
            ) : (
              ""
            )}
            {rankingList.map((ranking, index) => (
              <Grid item md={4}>
                <Box
                  style={{
                    minHeight: "100%",
                    borderRadius: "6px",
                    border: "1.3px solid #d7ecff",
                    backgroundColor: "white",
                    boxShadow: "none",
                    padding: "15px",
                  }}
                >
                  <div
                    style={{
                      margin: "-5px 0px 0px 0px",
                      fontSize: "18px",
                      color: "#084594",
                      fontFamily: "Lato-Bold",
                    }}
                  >
                    {ranking["name"]}
                  </div>
                  <div
                    style={{
                      margin: "-5px 0px 0px 0px",
                      fontSize: "16px",
                      color: "#3182bd",
                      fontFamily: "Lato-Bold",
                    }}
                  >
                    {ranking["class_id"]}
                  </div>
                  <div
                    style={{
                      textAlignVertical: "center",
                      color: "black",
                      fontFamily: "Lato-Regular",
                      fontSize: "14px",
                    }}
                  >
                    {ranking["achievement"]}
                  </div>
                  <div style={{ fontSize: "14px", marginTop: "10px" }}>
                    {ranking["documents"].map((document) => (
                      <div>
                        <a
                          target="_blank"
                          style={{
                            textDecoration: "none",
                          }}
                          href={document["link"]} rel="noreferrer"
                        >
                          <Button
                            style={{
                              width: "100%",
                              backgroundColor: "white",
                              borderRadius: "6px",
                              marginBottom: "10px",
                              height: "35px",
                              border: "1px solid #ddd",
                              flexDirection: "row",
                              justifyContent: "space-between",
                              paddingLeft: "10px",
                            }}
                          >
                            <span
                              style={{
                                marginTop: "2px",
                                textAlignVertical: "center",
                                color: "#3182bd",
                              }}
                            >
                              {document["document"]}
                            </span>
                            <Box
                              style={{
                                padding: "5px",
                                height: "35px",
                                width: "60px",
                                backgroundColor: "#3283c9",
                                borderTopRightRadius: "6px",
                                borderBottomRightRadius: "6px",
                                marginRight: "-10px",
                              }}
                            >
                              <div
                                style={{
                                  color: "white",
                                  textAlign: "center",
                                  fontFamily: "Bahnschrift",
                                  marginTop: "2px",
                                }}
                              >
                                VIEW
                              </div>
                            </Box>
                          </Button>
                        </a>
                      </div>
                    ))}
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      marginBottom: "-5px",
                      marginRight: "-5px",
                    }}
                  >
                    <Fab
                      size="small"
                      style={{
                        transform: "scale(0.8)",
                        boxShadow: "none",
                        backgroundColor: "#3182bd",
                      }}
                      onClick={() => {
                        setEmail(ranking["email"]);
                        setAchievement(ranking["id"]);
                        setOpen2(true);
                      }}
                      aria-label="add"
                    >
                      <PriorityHighIcon
                        style={{
                          color: "white",
                        }}
                      />
                    </Fab>
                  </div>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Fragment>
      );
    }
  }

  if (!state.auth.isLoading) {
    if (state.auth.isAuthenticated) {
      if (state.auth.user.is_manager) {
        if (isLoading) {
          return (
            <Fragment>
              <Sidebar />
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  backgroundColor: "white",
                  marginTop: "46vh",
                }}
              >
                <CircularProgress />
              </div>
            </Fragment>
          );
        } else {
          return (
            <Fragment>
              <Sidebar
                tabs={
                  <Grid container spacing={1.5} justifyContent="flex-end">
                    <Grid item md={4}>
                      {selectedPage == "TEACHERLEAVES" ? (
                        <Button
                          fullWidth
                          value={"TEACHERLEAVES"}
                          onClick={handlePageSelect}
                          p={2}
                          style={{
                            backgroundColor: "#3182bd",
                            color: "white",
                            height: "30px",
                          }}
                          borderRadius={6}
                        >
                          <h3
                            style={{
                              marginTop: "17px",
                              fontSize: "15px",
                            }}
                          >
                            TEACHER LEAVES
                          </h3>
                        </Button>
                      ) : (
                        <Button
                          fullWidth
                          value={"TEACHERLEAVES"}
                          onClick={handlePageSelect}
                          p={2}
                          style={{
                            backgroundColor: "#d7ecff",
                            color: "white",
                            height: "30px",
                          }}
                          borderRadius={6}
                        >
                          <h3
                            style={{
                              marginTop: "17px",
                              fontSize: "15px",
                              color: "#084594",
                            }}
                          >
                            TEACHER LEAVES
                          </h3>
                        </Button>
                      )}
                    </Grid>
                    <Grid item md={4}>
                      {selectedPage == "PROFILES" ? (
                        <Button
                          fullWidth
                          value="PROFILES"
                          onClick={handlePageSelect}
                          p={2}
                          style={{
                            backgroundColor: "#3182bd",
                            color: "white",
                            height: "30px",
                          }}
                          borderRadius={6}
                        >
                          <h3
                            style={{
                              marginTop: "17px",
                              fontSize: "15px",
                            }}
                          >
                            PROFILES
                          </h3>
                        </Button>
                      ) : (
                        <Button
                          fullWidth
                          value="PROFILES"
                          onClick={handlePageSelect}
                          p={2}
                          style={{
                            backgroundColor: "#d7ecff",
                            color: "white",
                            height: "30px",
                          }}
                          borderRadius={6}
                        >
                          <h3
                            style={{
                              marginTop: "17px",
                              fontSize: "15px",
                              color: "#084594",
                            }}
                          >
                            PROFILES
                          </h3>
                        </Button>
                      )}
                    </Grid>
                    <Grid item md={4}>
                      {selectedPage == "RANKING" ? (
                        <Button
                          fullWidth
                          value="RANKING"
                          onClick={handlePageSelect}
                          p={2}
                          style={{
                            backgroundColor: "#3182bd",
                            color: "white",
                            height: "30px",
                          }}
                          borderRadius={6}
                        >
                          <h3
                            style={{
                              marginTop: "17px",
                              fontSize: "15px",
                            }}
                          >
                            RANKING
                          </h3>
                        </Button>
                      ) : (
                        <Button
                          fullWidth
                          value="RANKING"
                          onClick={handlePageSelect}
                          p={2}
                          style={{
                            backgroundColor: "#d7ecff",
                            color: "white",
                            height: "30px",
                          }}
                          borderRadius={6}
                        >
                          <h3
                            style={{
                              marginTop: "17px",
                              fontSize: "15px",
                              color: "#084594",
                            }}
                          >
                            RANKING
                          </h3>
                        </Button>
                      )}
                    </Grid>
                  </Grid>
                }
              />
              <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={() => setOpen(false)}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                  timeout: 500,
                }}
              >
                <Fade in={open}>
                  <div
                    style={{
                      position: "absolute",
                      top: "50%",
                      backgroundColor: "white",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      width: 400,
                      padding: "20px",
                    }}
                  >
                    <div style={{ marginTop: "-25px" }}>
                      <div
                        style={{
                          marginTop: "20px",
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        <IconButton size="small">
                          <CloseIcon
                            onClick={() => setOpen(false)}
                            style={{ color: "#3182bd" }}
                          />
                        </IconButton>
                      </div>
                      <h3>Approve this leave request?</h3>
                      <span>This action cannot be reversed.</span>
                      <br></br>
                      <br></br>
                      <div
                        style={{
                          display: "flex",
                          width: "100%",
                          justifyContent: "flex-end",
                        }}
                      >
                        <Button
                          onClick={() => approveLeave(true)}
                          style={{ height: "30px" }}
                          variant="contained"
                        >
                          Approve
                        </Button>
                        <Button
                          style={{ height: "30px", marginLeft: "10px" }}
                          variant="contained"
                          onClick={() => approveLeave(false)}
                        >
                          Reject
                        </Button>
                      </div>
                    </div>
                  </div>
                </Fade>
              </Modal>
              <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open1}
                onClose={() => setOpen1(false)}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                  timeout: 500,
                }}
              >
                <Fade in={open1}>
                  <div
                    style={{
                      position: "absolute",
                      top: "50%",
                      backgroundColor: "white",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      width: 400,
                      padding: "20px",
                    }}
                  >
                    <div style={{ marginTop: "-25px" }}>
                      <div
                        style={{
                          marginTop: "20px",
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        <IconButton size="small">
                          <CloseIcon
                            onClick={() => setOpen1(false)}
                            style={{ color: "#3182bd" }}
                          />
                        </IconButton>
                      </div>
                      <h3>What do you want to do with this account?</h3>
                      <span>This action cannot be reversed.</span>
                      <br></br>
                      <br></br>
                      <br></br>

                      <div
                        style={{
                          display: "flex",
                          width: "100%",
                          justifyContent: "flex-end",
                        }}
                      >
                        <Button
                          onClick={() => {
                            setSelectedApprove(true);
                            setOpen3(true);
                          }}
                          style={{ height: "30px" }}
                          variant="contained"
                        >
                          Approve
                        </Button>
                        <Button
                          style={{ height: "30px", marginLeft: "10px" }}
                          variant="contained"
                          onClick={() => {
                            setSelectedApprove(false);
                            setOpen3(true);
                          }}
                        >
                          Reject
                        </Button>
                        <Button
                          onClick={() => {
                            setSelectedApprove("DELETE");
                            setOpen3(true);
                          }}
                          style={{
                            height: "30px",
                            marginLeft: "10px",
                            backgroundColor: "#ff3333",
                          }}
                          variant="contained"
                        >
                          Delete
                        </Button>
                        <Button
                          onClick={() => {
                            setSelectedPage("TEACHERLEAVES");
                            setSeeLeaves(true);
                            setOpen1(false);
                          }}
                          style={{
                            height: "30px",
                            marginLeft: "10px",
                            backgroundColor: "#1c9099",
                          }}
                          variant="contained"
                        >
                          Leaves
                        </Button>
                      </div>
                    </div>
                  </div>
                </Fade>
              </Modal>

              <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open3}
                onClose={() => setOpen3(false)}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                  timeout: 500,
                }}
              >
                <Fade in={open3}>
                  <div
                    style={{
                      position: "absolute",
                      top: "50%",
                      backgroundColor: "white",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      width: 400,
                      padding: "20px",
                    }}
                  >
                    <div style={{ marginTop: "-25px" }}>
                      <div
                        style={{
                          marginTop: "20px",
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        <IconButton size="small">
                          <CloseIcon
                            onClick={() => setOpen3(false)}
                            style={{ color: "#3182bd" }}
                          />
                        </IconButton>
                      </div>
                      <h3>Are you sure?</h3>
                      <span>This action cannot be reversed.</span>
                      <br></br>
                      <br></br>
                      <br></br>

                      <div
                        style={{
                          display: "flex",
                          width: "100%",
                          justifyContent: "flex-end",
                        }}
                      >
                        <Button
                          onClick={() => setOpen3(false)}
                          style={{ height: "30px" }}
                          variant="contained"
                        >
                          NO
                        </Button>
                        <Button
                          style={{ height: "30px", marginLeft: "10px" }}
                          variant="contained"
                          onClick={() => approveProfile(selectedApprove)}
                        >
                          YES
                        </Button>
                      </div>
                    </div>
                  </div>
                </Fade>
              </Modal>

              <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open2}
                onClose={() => setOpen2(false)}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                  timeout: 500,
                }}
              >
                <Fade in={open2}>
                  <div
                    style={{
                      position: "absolute",
                      top: "50%",
                      backgroundColor: "white",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      width: 400,
                      padding: "20px",
                    }}
                  >
                    <div style={{ marginTop: "-25px" }}>
                      <div
                        style={{
                          marginTop: "20px",
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        <IconButton size="small">
                          <CloseIcon
                            onClick={() => setOpen2(false)}
                            style={{ color: "#3182bd" }}
                          />
                        </IconButton>
                      </div>
                      <h3>Approve this achievement?</h3>
                      <span>This action cannot be reversed.</span>
                      <br></br>
                      <span
                        style={{
                          color: "#3182bd",
                          fontFamily: "Lato-Regular",
                          fontSize: "14px",
                        }}
                      >
                        Points
                      </span>
                      <TextField
                        style={{ marginTop: "2px", marginBottom: "15px" }}
                        value={points}
                        onChange={(e) => setPoints(e.target.value)}
                        fullWidth
                        placeholder="Achievement Points"
                        variant="outlined"
                        size="small"
                      />{" "}
                      <br></br> <br></br>
                      <div
                        style={{
                          display: "flex",
                          width: "100%",
                          justifyContent: "flex-end",
                        }}
                      >
                        <Button
                          onClick={() => approveAchievement(true)}
                          style={{ height: "30px" }}
                          variant="contained"
                        >
                          Approve
                        </Button>
                        <Button
                          style={{ height: "30px", marginLeft: "10px" }}
                          variant="contained"
                          onClick={() => approveAchievement(false)}
                        >
                          Reject
                        </Button>
                      </div>
                    </div>
                  </div>
                </Fade>
              </Modal>
              <div
                style={{
                  margin: "65px 15px 20px 85px",
                  backgroundColor: "white",
                }}
              >
                {getPageContent()}
              </div>
            </Fragment>
          );
        }
      } else {
        dispatch({
          type: "AUTH_ERROR",
        });
        return <Navigate to="/login" />;
      }
    } else {
      return <Navigate to="/login" />;
    }
  } else {
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          backgroundColor: "white",
          marginTop: "46vh",
        }}
      >
        <CircularProgress />
      </div>
    );
  }
}

export default Approvals;
