import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import store from "./store";
import { Provider } from "react-redux";
import { createBrowserHistory } from "history";
import { BrowserRouter as Router } from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";
import Button from "@mui/material/Button";
import axios from "axios";
import { API_URL } from "./actions/auth";

const history = createBrowserHistory();

function fallbackRender({ error, resetErrorBoundary }) {
  var body = {};

  body["webapp"] = "ELEMZY TEACHER";
  body['error'] = error

  axios
    .post(API_URL + "/log_error_web", body)
    .then((res) => {
      console.log("ERROR REPORT SENT");
    })
    .catch((err) => {});

  return (
    <div
      style={{
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "rgba(33, 41, 100, 0.9)",
      }}
    >
      <div
        style={{
          boxShadow: "0 8px 18px 0 rgba(0,0,0,0.2)",
          padding: "20px",
          borderRadius: "6px",
          backgroundColor: "white",
          paddingRight: "20px",
          width: "450px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            color: "black",
          }}
        >
          <div style={{ marginTop: "-7px" }}>
            <span style={{ fontSize: "32px" }}>Oops!</span>
            <br></br>
            <span style={{ fontSize: "20px" }}>An error occured.</span>
            <br />
            <span style={{ fontSize: "15px", color: "grey" }}>{error}</span>
          </div>
          <a
            style={{ textDecoration: "none" }}
            href={"https://principal.elemzy.com"}
          >
            <img
              style={{ maxHeight: "60px" }}
              src={require("./img/onlylogo.png")}
            />
          </a>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "20px",
          }}
        >
          <Button
            style={{ height: "30px", backgroundColor: "#3283c9" }}
            onClick={() => resetErrorBoundary()}
            variant="contained"
            color="primary"
          >
            <span
              style={{
                fontSize: "18px",
                width: "100px",
              }}
            >
              RETRY
            </span>
          </Button>
        </div>
      </div>
    </div>
  );
}

ReactDOM.render(
  <ErrorBoundary
    fallbackRender={fallbackRender}
    onReset={(details) => {
      // Reset the state of your app so the error doesn't happen again
    }}
  >
    <Provider store={store}>
      <Router history={history}>
        <App />
      </Router>
    </Provider>
  </ErrorBoundary>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
