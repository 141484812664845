import CircularProgress from "@mui/material/CircularProgress";
import { useSelector, useDispatch } from "react-redux";
import { Link, Navigate, useNavigate } from "react-router-dom";

function Loader() {
  const params = new URLSearchParams(window.location.search);
  const state = useSelector((state) => state);

  console.log(state.auth.entryPage);

  if (state.auth.isLoading) {
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          backgroundColor: "white",
          marginTop: "46vh",
        }}
      >
        <CircularProgress />
      </div>
    );
  } else {
    if (state.auth.entryPage == "/") {
      return <Navigate to="/dashboard" />;
    } else {
      return <Navigate to={state.auth.entryPage} />;
    }
  }
}

export default Loader;
